
.infoHolder {
    display: flex;
    margin-bottom: 30px;
    margin-top: 20px;
}

.infoDiv {
    flex-grow: 0;
    padding: 22px 26px;
    background: #fff;
    margin-right: 28px;
    border-radius: 4px;
    background-color: #fff;
    font-weight: 500;
    position: relative;
    width: 275px;
    display: table;
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
}

.infoDiv .info {
    display: table-cell;
    vertical-align: middle;
}

.infoDiv p {
    margin: 0px;
    color: #fff
}

.infoDiv .infoIcon {
    position: absolute;
    font-size: 58px;
    bottom: 32px;
    right: 22px;
    color: rgba(0,0,0,0.18);
    width: 58px;
    height: 58px;
}
.infoDiv .infoIcon img {
    max-width: 100%;
}
.infoDiv .infoCount {
    font-size: 32px;
    color: #fff;
    letter-spacing: 1.2px;
}

.infoDiv .infoTitle {
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    margin-top: 2px;
}

.infoHolder .infoDiv:nth-child(1) {
   /* background-color: #ef6753;
    background-color: #768c94;*/
    background-image: linear-gradient(#ecaf8b 30%,#f06b45);

}
.infoHolder .infoDiv:nth-child(1) .infoIcon {
    /*color: #d54b76;
    color: #6d7e84 */
    width: 46px;
}

.infoHolder .infoDiv:nth-child(2) {
    /*background-color: #51b39e*/
    background-image: linear-gradient(#91b0a8, #5abb8f);
}
.infoHolder .infoDiv:nth-child(2) .infoIcon {
    /*color: #48a18e */
}

.infoHolder .infoDiv:nth-child(3) {
    /*background-color: #ef6753;
    background-color: #f08081;*/
    background-image: linear-gradient(#d4a2b0, #7b4596);
}

.infoHolder .infoDiv:nth-child(3) .infoIcon {
    /* color: #d54b76;
    color: #dc696a; */
    width: 46px;
}

.infoHolder .infoDiv:nth-child(4) {
    /*background-color: #f8a836;
    background-color: #ba79cb;*/
    background-image: linear-gradient(#9ec7d2, #1e337f);
    background-image: linear-gradient(#FDD835, #7f5c1e);
    background-image: linear-gradient(#c7d62f, #827717);
}
.infoHolder .infoDiv:nth-child(4) .infoIcon {
    /* color: #e59831;
    color: #a66db6 */
    width: 52px;
}
