

.editForm {
  display: "flex";
  flex-wrap: "wrap";
}

.editForm .label {
  width: 100%;
}

.editForm h4 {
  margin: 0px;
  font-weight: normal;
}

/* .editForm .Mui-expanded h4 {
  font-weight: bold;
} */

.editForm .Mui-expanded #scoring-panel h4 {
  font-size: 1rem;
  font-weight: 400;
  /* color: #2296f3; */
}

/* .editForm .MuiAccordion-root {
  border: 1px solid #BDBDBD;
} */

.data-sources-widget {
  padding: 0px;
}

.data-sources-widget section {
  margin-bottom: 20px;
}

.dataSourceSelectHeading {
  display: flex;
}
.dataSourceSelectHeading > span {
  min-width: 130px;
}

.dataSourceSelectBox > div {
  width: 100%;
}

.dataSourceSelect {
  margin-bottom: 30px;
}

.dataCtalogSelect {
  position: relative;
  margin-top: 8px;
  height: 50px;
}
.dataCtalogSelect > input {
  position: absolute;
  top: 15px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #959595;
  height: 38px;
  font-size: 16px;
}
.dataCtalogSelect > input::placeholder {
  color: rgba(0, 0, 0, 0.4);
}
.dataCtalogSelect .opts {
  position: absolute;
  top: 20px;
  right: 8px;
  z-index: 99;
  background: #fff;
  height: 24px;
  cursor: pointer;
}

.dataCtalogSelect .opts svg {
  color: #ea6a6a;
}

.dataCtalogSelect .opts .disabled {
  cursor: initial;
}

.dataCtalogSelect .opts .disabled svg {
  pointer-events: none;
  color: #bbb;
}

.fileRoot {
  display: flex;
  align-items: center;
}

.fileRoot svg {
  color: #2196f3;
}

.dataCtalogSelect .hidden-input {
  opacity: 0;
  position: absolute;
  top: 0;
}

.upload-input-container {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 12px;
  padding-bottom: 6px;
  border-bottom: 1px solid gray;
  font-size: 16px;
  color: gray;
  cursor: pointer;
}

.upload-input-container button {
  padding: 0;
  margin-right: 12px;
}

.upload-input-container-disabled {
  cursor: initial;
}

.upload-input-container-disabled svg {
  color: #bbb !important;
}

.formControl {
  width: 100%;
}

.selectFormControl {
  margin: 16px 0px 8px 0px !important;
  width: 100%;
}

.dataSourceContent {
  margin: 0px;
  padding: 5px 20px 30px;
  border: 1px solid #efefef;
  background: #f3f3f3;
  border-radius: 4px;
}

.socialMedia {
  position: relative;
}

.socialMedia .dataSourceContent {
  padding: 0px;
}

.dataSourceContent .MuiFormLabel-root {
  font-size: 0.82rem;
}

.dataSourceContent .errorMsg {
  margin-top: -5px;
  display: block;
}

#twitter-details > button {
  background-color: #1ca1f3;
  color: #fff;
}

#twitter-details > button:hover {
  background-color: #3bc0f7;
}

.socialMediaBtn {
  height: 56px;
  line-height: 56px;
  overflow: hidden;
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
    rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
}

/* .socialMediaBtn .socialMediaBtnContent {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
} */

.socialMediaBtn .socialMediaBtnContent > span:first-child {
  border-right: 2px solid;
  border-color: rgba(255, 255, 255, 0.4);
}

.socialMediaBtnContent > span {
  color: #fff;
  padding: 0 20px;
  font-weight: bold;
}

/*
.socialMediaBtn i {
    font-size: 26px;
    background: #1ca1f3;
    border: none;
    padding: 13px 20px 0px;
    color: #fff;
    height: 100%;
    float: left;
    border-right: 2px solid;
    border-color: rgba(255,255,255,0.4)
} */

#twitter-details .socialMediaBtn,
#twitter-details .socialMediaBtn i {
  background: #38a1f3;
}

#twitter-details .socialMediaBtn:hover {
  background: #2c97ea;
}

#facebook-details .socialMediaBtn,
#facebook-details .socialMediaBtn i {
  background: #3b5998;
}

#facebook-details .socialMediaBtn:hover {
  background: #355496;
}

.dataSourceContent .btn img {
  width: 14px;
  margin-right: 15px;
}

.btn.btn-bazzarVoice {
  background: #fff;
  color: #47bd84;
  border: 1px solid #47bd84;
}

.btn.btn-bazzarVoice:hover {
  background: #dbf7e9;
}

.btn.btn-typeform {
  background: #fff;
  color: #33414e;
  border: 1px solid #33414e;
}
.btn.btn-typeform:hover {
  border: 1px solid #04315a;
}

.btn.btn-localytics {
  margin-top: 12px;
  background: #fff;
  color: #33414e;
  border: 1px solid #ff4300;
}
.btn.btn-localytics:hover {
  border: 1px solid #ff0400;
}

.btn.btn-wufoo {
  background: #fff;
  color: #33414e;
  border: 1px solid #d66552;
}
.btn.btn-wufoo:hover {
  border: 1px solid #a71e07;
}

.inprogressHelp {
  color: #b1b1b1;
  margin-left: 20px;
  font-size: 20px;
}

.newButton {
  margin-bottom: 40px;
  margin-top: 10px;
}

.datasetLabel{
  margin: .2em;
  padding: .2em;
}

.rescoreDialog {
  padding: 20px;
}

/* Brand Info - css */

.brandImgHolder {
  position: relative;
  display: table;
  width: 100%;
}

.brandImage {
  position: relative;
  border: 1px solid #a7a7a7;
  padding: 2px;
  width: 100%;
  min-height: 150px;
  overflow: hidden;
  max-height: 180px;
  transition: all 0.2s;
  background-size: cover;
  background-color: #cacaca;
  background-repeat: no-repeat;
  background-position: center;
}

.brandImgHolder:hover .brandImage {
  border: 2px solid #fff;
}

.brandImage input {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.brandImage img {
  width: 100%;
}

.brandImgHolder .MuiIconButton-root {
  position: absolute;
  top: 4px;
  right: 4px;
  opacity: 0;
  color: #d61313;
  padding: 4px;
}

.brandImgHolder:hover .MuiIconButton-root {
  opacity: 1;
  background-color: rgba(255, 255, 255, 1) !important;
}

.brandImgHolder > p {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  text-align: center;
}

/* Data Source - css */
.accordianBody {
  padding-bottom: 40px !important;
}

.dataSourceTable {
  position: relative;
  max-height: 320px;
  overflow: auto;
  padding-bottom: 10px;
}
.accordianTables {
  width: 100%;
  /* border: 1px solid rgba(224, 224, 224, 1);*/
  border-collapse: collapse;
}

.accordianTables thead tr th {
  /* color: #618797; */
  color: rgba(0, 0, 0, 0.8);
  padding: 15px;
  font-weight: 500;
  font-size: 12px;
  background-color: rgba(224, 224, 224, 0.24);
  background-color: rgb(226, 226, 226);
  /* border-bottom: 1px solid rgba(224, 224, 224, 1);*/
}

.accordianTables th,
.accordianTables td {
  /* border: 1px solid rgba(224, 224, 224, 1); */
  border-collapse: collapse;
  padding: 15px 12px;
}

.accordianTables th {
  /* background: #deebf7; */
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.accordianTables thead tr th:last-child,
.accordianTables tbody tr td:last-child {
  padding: 15px 25px 15px 15px;
  text-align: right;
}

.accordianTables tbody tr {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}
.accordianTables tbody tr:last-child {
  border-bottom: 0px solid rgba(224, 224, 224, 1);
}

.accordianTables tbody tr td {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.89);
  padding: 15px;
}

.accordianTables tbody tr td a {
  /* color: #2196f3 */
  color: #4c4c4c;
}

.canDelete {
  cursor: pointer;
  color: #ff5e31;
}

.cannotDelete {
  cursor: inherit;
  color: #5caf5b;
  position: relative;
}

.cannotDelete button {
  /*position: absolute;
  left: -1px;
  top: 0px; */
  background: #fff !important;
  color: #ff5e31;
  font-size: 14px;
  padding: 0px;
  display: none;
  width: 13px;
}

.exportDataSource svg {
  font-size: 18px;
}

.data-source-row:hover .cannotDelete button {
  display: block;
}
.data-source-row:hover .cannotDelete .fa-check {
  display: none;
}

.canDelete .MuiIconButton-sizeSmall {
  padding: 1px;
}

.canDelete .MuiSvgIcon-root {
  width: 16px;
  height: 16px;
}

.canDelete i {
  font-size: 14px;
}

.data-source-row .MuiIconButton-sizeSmall {
  padding: 5px;
}

.accordianTables tbody tr.data-source-row td {
  padding: 8px 16px;
}

.exportDataSource .MuiIconButton-sizeSmall {
  padding: 0px;
}

.preProcessingSettings .MuiIconButton-sizeSmall {
  padding: 4px 1px 1px;
}

.preProcessingSettings .MuiSvgIcon-root {
  width: 16px;
  height: 16px;
}

.showDetails {
  cursor: pointer;
  text-decoration: underline;
  padding: 0px;
  /* color: #0f81c3; */
}

.showDetails:hover {
  text-decoration: none;
}

/*
.accordianTables tbody tr td > div.csv_details {
    position: relative;
    margin: 10px 0px 20px 0px;
    font-size: 12px;
    padding-top: 10px;
    padding: 15px;
    border-radius: 4px;
    background: #f0f8ff;
} 

.csv_details:hover {
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)
}*/

.csvDiv {
  padding-top: 6px;
}

.csvDiv > strong {
  margin-bottom: 3px;
  display: inline-block;
}

.csvDiv > p {
  margin: 0 0 5px 0;
  padding-left: 10px;
}

.csvDiv > p > strong {
  color: rgb(98, 145, 153);
  font-style: italic;
  padding-right: 10px;
}

.closeBtnRounded {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 16px;
  cursor: pointer;
  color: #a4c3da;
}
.closeBtnRounded:hover {
  color: #36709a;
}

/* Out Bound Data Source - Css */
.databasetab-panel {
  display: flex;
}

.outboundDatabase h3 {
  color: rgb(102, 102, 102);
  color: rgb(33, 115, 182);
  margin-top: 15px;
}

.outboundDBoptions {
  margin-top: 25px;
  padding: 20px;
  background: #f2f2f2;
}

/* Scoring - Css */
.flexGrow-01 {
  flex-grow: 1;
}

.flexGrow-01 .rowGrid {
  margin-bottom: 20px;
}

.flexGrow-01 .rowGrid .innerGrid {
  padding: 0 15px;
}

.modelTree {
  padding: 10px;
  margin-top: 30px;
  max-height: 250px;
  background: #e6eef2;
  overflow: auto;
}

.modelTree .rc-tree li span.rc-tree-switcher {
  color: #545454;
}

.modelPlaceHolder {
  margin: 15px 0 0 0;
  padding: 20px;
  height: 84px;
  overflow: hidden;
  border-radius: 2px;
  background: #e6eef2;
  color: #fff;
}

.modelPlaceHolder p {
  padding: 10px 20px 13px;
  border-radius: 50px;
  font-weight: normal;
  color: #222;
  display: inline-block;
  margin: 0;
  background: #fbd16a;
  color: #fff;
  background: #b3719a;
}

.topicDiscovery {
  display: flex;
  font-weight: bold !important;
  justify-content: space-between;
  align-items: flex-end;
  max-height: 42px;
}

.innerDivision {
  display: flex;
}

/* .topicDiscovery > div {
  flex-grow: 1;
} */

.renderThreshold {
  padding: 0px 0px 0px 25px;
}

.renderThreshold div {
  width: 120px;
  height: 112px;
  background: #f4f4f4;
  text-align: center;
  display: table;
}
.renderThreshold div span {
  display: table-cell;
  vertical-align: middle;
  font-size: 32px;
  color: #1b9be5;
}

.renderThreshold p {
  font-size: 16px;
  margin: 0;
  height: 42px;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.sliderDiv { 
  flex: 1 1 56%;
}

.sliderHolder {
  width: 100%;
  position: relative;
  background: #f4f4f4;
  padding: 50px 15px 62px 20px;
  margin-top: 6px;
}

.sliderHolder > span {
  position: absolute;
  font-size: 14px;
  font-weight: bold;
  width: 85%;
}

.sliderHolder > span.sliderMin {
  left: 15px;
  top: 18px;
  width: 10px;
  display: none;
}
.sliderHolder > span.sliderMax {
  right: 15px;
  top: 18px;
  width: 10px;
  display: none;
}

/* #menu- .MuiListItem-root.Mui-selected {
  background-color: rgb(76, 76, 76);
  color: #fff;
} */
.dsdetails {
  position: relative;
  margin: 5px 0px 0px 0px;
  font-size: 12px;
  padding-top: 10px;
  padding: 10px;
  border-radius: 4px;
  background: #f0f8ff;
}
.dsdetails:hover {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}
.dsdetails p {
  margin: 0px 0 2px 0;
}

.dsdetails p strong,
.dsdetails p b {
  color: rgb(98, 145, 153);
  font-style: italic;
  padding-right: 10px;
}

.accordianDiv + .editFooter {
  margin: 24px 0px 5px;
}

.hiddenFacebookBtn {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.hiddenFacebookBtn > span {
  width: 100%;
  display: block;
  height: 100%;
  transition: none !important;
}

.hiddenFacebookBtn > span button {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.socialMedia:hover #facebook-details .socialMediaBtn {
  background: #355496;
}

.hiddenTwitterBtn > button {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.socialMedia:hover #twitter-details .socialMediaBtn {
  background: #2c97ea;
}

.hiddenFacebookBtn > button {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.uploadPreloader {
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 111;
  display: table;
}

.uploadPreloader p {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.rescore-options { margin: 25px 0px 0px 0px }
.rescore-options .MuiFormLabel-root { color: #2173b6; font-weight: 500; }
.rescore-options .MuiFormGroup-root { flex-direction: row }

#threshhold-info .MuiTooltip-tooltip {
  background-color: rgb(33 33 33);
  font-weight: 400;
}

.atdSeedFileUpload {
  background-color: #f4f4f4;
  margin-top: 6px;
  padding: 0px 20px 15px;
  border: 1px solid #efefef;
}

.seedFile {
  margin-top: 6px;
}

.seedFileTable {
  width: 100%;
}

.seedFileTable table {
  width: inherit;
  height: 112px;
  border-collapse: collapse;
  border-spacing: 0;
}

.seedFileTable thead tr th {
  padding: 16px !important;
  font-weight: 500;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
  background-color: rgb(179, 177, 177);
}

.seedFileTable tbody tr td {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.89);
  padding: 15px;
  background-color: #f4f4f4;
}

.atdSeedFileUploadInput {
  position: relative;
  height: 50px;
}

.atdSeedFileUploadInput > input {
  position: absolute;
  top: 15px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #959595;
  height: 38px;
  font-size: 16px;
}
.atdSeedFileUploadInput > input::placeholder {
  color: rgba(0, 0, 0, 0.4);
}
.atdSeedFileUploadInput .opts {
  position: absolute;
  top: 20px;
  right: 8px;
  z-index: 99;
  height: 24px;
  cursor: pointer;
}

.atdSeedFileUploadInput .opts svg {
  color: #ea6a6a;
}

.atdSeedFileUploadInput .opts .disabled {
  cursor: initial;
}

.atdSeedFileUploadInput .opts .disabled svg {
  pointer-events: none;
  color: #bbb;
}

.atdSeedFileUploadInput .hidden-input {
  opacity: 0;
  position: absolute;
  top: 0;
}

.atdUploadFooter {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.seedFileSample {
  font-weight: 600 !important;
  font-style: italic; 
}

.atdSeedFileUploadBtn .fileUploading {
  position: absolute;
}

.thresholdSliderTitle {
  display: flex;
  height: 42px;
  align-items: center;
}

.thresholdSliderTitle span {
  font-size: 16px;
  margin-right: 16px;
  line-height: 1.5;
}

.decooda-pipeline-select {
  min-width: 80px;
  margin-right: 5px;
  margin-bottom: 2px;
}

.decooda-pipeline-select .MuiInputBase-root {
  font-size: 14px !important;
}

.seedFileTable .MuiSvgIcon-root {
  font-size: 15px;
  cursor: pointer;
}

.atdOptions {
  padding-left: 15px;
}

.dataCatalogueModal {
  width: 100%;
  max-height: 420px;
}

.dataCatalogueModal .MuiSkeleton-rect {
  border-radius: 8px;
  margin: 6px 0px;
}

.dataCatalogueModal em {
  color: gray;
  font-size: 1em;
  margin: 1em;
}

.dateCatalogueModalHeader {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}

#data-catalogue {
  padding-bottom: 0 !important;
}

.dataCatalogueContent {
  padding-top: 0 !important;
}

.outboundDBoptions .MuiInputBase-root.Mui-disabled {
  color: rgb(0 0 0 / 48%);
}

.outBoundTable {
  overflow: scroll;
}

.languagePlaceholder .MuiSelect-select.MuiSelect-select {
  font-size: 14px;
}

.model-close-icon {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}

.pos-rel { 
  position: relative
}
.hiddenShare { display: none !important; }