.breadcrumbDiv {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.breadcrumbDiv > .breadcrumb {
  display: inline-block;
  font-weight: 400;
  color: rgba(95, 95, 95, 1);
  cursor: pointer;
}

.breadcrumbDiv > .breadcrumb:hover {
  color: rgba(8, 110, 193, 1);
}

.breadcrumbDiv > .breadcrumb:last-child {
  cursor: default;
  color: rgba(0, 0, 0, 1);
  pointer-events: none;
}

/*
.breadcrumb:before {
    content: "\F101";
    color: rgba(95, 95, 95, 0.7);
    vertical-align: top;
    display: inline-block;
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 25px;
    margin: -3px 8px 0 8px;
    -webkit-font-smoothing: antialiased;
} */

.breadcrumb:before {
  content: "\F105";
  content: "/";
  color: rgba(95, 95, 95, 1);
  font-family: "FontAwesome";
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  margin: 0px 8px 0 8px;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
}

.breadcrumb:first-child:before {
  display: none;
}
