.noAccess {
    margin: 24px 0px;
    padding: 24px;
    color: #8e5656;
    background-color: rgba(228, 171, 171, 0.48);
}
.noAccess h3 {
    margin: 0 0 5px 0px;
}
.noAccess small {
    font-size: 14px;
}