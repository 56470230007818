.OutlierTable table {
  border-collapse: collapse;
  width: 400px;
}

.OutlierTable > th{
    color: #ccc;
    font-size: 14px;
    text-align: left;
}

.OutlierTable > td{
  font-size: 14px;
  text-align: left;
}


.OutlierTable td img {
  align-items: center;
  cursor: pointer;
}

.OutlierTable .icon {
  text-align: center;
}

.OutlierTable h3 {
  font-size: 14px;
}
