.orange{
  background-color: #ffcc80 !important;
  /* border: 2px solid black !important;  */
}

.oB {
  background-color: #ffcc80 !important;
  border: 1px solid black !important; 
}

.green{
  background-color: #ccff90 !important;
}

.gB {
  background-color: #ccff90 !important;
  border: 1px solid black !important; 
}

.yellow{
  background-color: #fff176 !important;
}

.yB {
  background-color: #fff176 !important;
  border: 1px solid black !important; 
}


.purple{
  background-color: #d1c4e9 !important;
}

.pB {
  background-color: #d1c4e9 !important;
  border: 1px solid black !important; 
}

.pink{
  background-color: #f8bbd0 !important;
}

.piB {
  background-color: #f8bbd0 !important;
  border: 1px solid black !important; 
}

.indigo{
  background-color: #c5cae9 !important;
}

.iB {
  background-color: #c5cae9 !important;
  border: 1px solid black !important; 
}

.lime{
  background-color: #d4e157 !important;
}

.lB {
  background-color: #d4e157 !important;
  border: 1px solid black !important; 
}

.brown{
  background-color: #d7ccc8 !important;
}

.bB {
  background-color: #d7ccc8 !important;
  border: 1px solid black !important; 
}

.lightblue{
  background-color: #81d4fa !important;
}

.black{
  background-color: black !important;
}

.palette-mode{
  cursor: url('../../../assets/images/paint-roller-512.png'), auto;
}

.noColor{
  background-color: transparent !important;
  border: 1px solid black !important; 
}

.palette-minimized {
  background-color: #1890FF !important;
  color: #fff !important;
}

