@import "../atd-styles/atdColorPalette.scss";

#alert-message>.MuiBackdrop-root {
    /* background-color: rgb(255 255 255 / 98%) !important; */
    background-color: rgba(255, 255, 255, 0.8) !important;
}

body.theme01.atd-screen {
    background-color: $backgroundColor !important;
    overflow: hidden;
}

body.theme01.atd-screen.sidebar-compact .app-body {
    height: calc(100vh - 72px);
    min-height: 100px;
    padding: 20px;
    position: fixed;
    top: 72px;
    width: calc(100% - 72px);
}

body.theme01.atd-screen .app-body {
    width: calc(100% - 256px);
}

.atd-screen .MuiCardContent-root:last-child {
    padding-bottom: 16px;
}

.phrase-section {
    //height: calc(100vh - 335px);
    height: 100%;
    position: relative;
}

.phrase-section>div {
    height: inherit;
}

.phrases-holder {
    height: inherit;
    transition: height 0.4s;
}

.card-box.MuiPaper-outlined {
    border: 1px solid #BDBDBD;
    height: 100%;
}

.card-box .card-header {
    display: flex;
    align-items: center;
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #fff;
    /* border-bottom: 0 solid var(cardHeaderBorder); */
}

.card-box .card-header--title {
    flex-grow: 1;
    flex-basis: 140px;
    min-width: 140px;
}

.card-box .card-header--actions {
    text-align: right;
    flex-basis: calc(100% - 140px);
    min-width: calc(100% - 140px);
}

.card-header--actions .MuiTextField-root {
    width: 100px;
    margin-right: 10px;
}

.card-header--actions .MuiTextField-root.search-phrases,
.card-header--actions .MuiInputBase-adornedEnd {
    width: 280px;
}

.card-header--actions .MuiButtonGroup-root, 
.card-header--actions .MuiInputBase-root {
    margin-right: 10px;
}

.card-header--actions .MuiButtonGroup-root button {
    text-transform: none;
    padding: 8px 10px;
}

.topics-holder .card-box .card-header--actions {
    flex-basis: calc(100% - 300px);
    min-width: calc(100% - 300px);
}

.pr-2,
.px-2 {
    padding-right: 0.5rem !important;
}

.card-box .card-footer,
.card-box .card-header {
    border-width: 1px;
    border-color: var(cardHeaderBorder);
}

.card-header:first-child {
    border-radius: 0.65rem 0.65rem 0 0;
}

.card-box .card-header--title .card-info {
    white-space: nowrap;
}

.card-box .card-header--title .card-info small {
    text-transform: uppercase;
    display: block;
    opacity: .3;
    font-weight: bold;
}

small,
.small {
    font-size: 80%;
    font-weight: 400;
}

.card-box .card-header--title .selected-topic-info {
    margin-left: 10px;
    padding-left: 15px;
    border-left: 2px solid $borderColor;
}

.card-box .card-header--title .selected-topic-info span {
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 400;
    opacity: .6;
    white-space: nowrap;
    font-style: italic;
    display: block;
    font-family: serif;
}

.card-box .more-options .MuiIconButton-root {
    padding: 5px;
}

.badge {
    display: inline-flex !important;
    margin: 0.25rem 0.25rem 0.3rem 0.25rem !important;
    text-transform: uppercase;
}

.badge-danger {
    color: #fff;
    background-color: var(redColor) !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.topics-holder .badge {
    cursor: pointer;
}

.topics-holder .seedTopic.badge {
    border-style: double;
    border-width: 3px;
}

.topics-holder .MuiChip-root {
    background-color: rgb(217, 217, 217);
}

.topics-holder .MuiChip-outlined {
    background-color: transparent;
    border: 1px solid $borderColor;
}

.topics-holder .MuiChip-outlined.seedTopic.badge {
    border: none;
    border-style: double;
    border-width: 3px;
}

.topics-holder .MuiChip-colorPrimary {
    color: #fff;
    background-color: $topicPrimary !important;
    text-align: center;
}

.topics-holder .MuiChip-colorSecondary {
    color: #fff;
    background-color: $topicSecondary !important;
}

.topics-holder .MuiChip-outlinedPrimary {
    color: #69a009;
    border: 1px solid #69a009;
    background-color: transparent !important;
}

.topics-holder .MuiChip-outlinedPrimary.seedTopic.badge {
    border: none;
    border-style: double;
    border-width: 3px;
}

.topics-holder .MuiChip-outlinedSecondary {
    color: #e86740;
    border: 1px solid #e2613e;
    background-color: transparent !important;
}

.topics-holder .MuiChip-outlinedSecondary.seedTopic.badge {
    border: none;
    border-style: double;
    border-width: 3px;
}

.MuiChip-label {
    overflow: hidden;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;
    text-overflow: ellipsis;
    font-weight: 500;
}

.phrase-parent {
    /* margin-bottom: 20px; */
}

.phrase-title {
    border: 1px solid #bbb;
    border-radius: 5px;
    text-align: center;
    height: 100%;
    width: 100%;
    cursor: pointer;
}

.phrase-title.selected {
    border: 1px solid #4a90ff;
    color: #4a90ff;
    border: 1px solid #000;
    color: #000;
    font-weight: 500;
    cursor: move;
}

/* .phrase-title>div {
    display: table-cell;
    vertical-align: middle;
} */

.phrase-title .positive .fa {
    color: $positiveSentiment;
}

.phrase-title .negative .fa {
    color: $negativeSentiment;
}

.phrase-title.selected .isDrag {
    cursor: move;
}

.phrase-title .phrase-card-top {
    position: relative;
    height: 16px;
    margin-top: -10px;
}

.phrase-card-top .seed-icon {
  position: absolute;
  right: 0;
  padding: 0px 2px;
  border: 1px solid black;
  border-radius: 50%;
  font-size: 10px;
  line-height: 11px;
  font-weight: 500;
  margin-top: 4px;
}

.phrase-card-top .ngram-topic-list-icon {
  position: absolute;
  left: 0;
  cursor: pointer;
  margin-top: 4px;
}

.ngram-topic-list-icon svg {
  font-size: 16px;
}

.phrase-card-top .MuiPopover-paper {
  border-radius: 8px !important;
}

.ngram-topics-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  background-color: #c8e4fb;
  max-height: 150px;
}

.ngram-topics-list h6 {
  margin: 4px 0;
}

.ngram-topics-list .MuiButton-root {
  padding: 2px;
  text-transform: capitalize;
  color: #1769aa;
}

.MuiListItem-button {
    text-transform: capitalize;
}

.content-holder {
    height: 100%;
    position: relative;
}

.phrase-list {
    /* max-height: 280px;
    overflow: auto; */
}

.phrase-list-items {
    padding: 0px;
    position: relative;
    margin-bottom: 5px;
    line-height: 20px;
}

.candidates .phrase-list-items {
    cursor: text;
}

/* .phrase-list-items::before {
    content: ">";
    position: absolute;
    top: 1px;
    left: 2px;
    font-weight: 600;
    transition: transform 0.2s;
}

.phrase-list-items.selected::before {
    transform: rotate(90deg);
} */

/* .phrase-list-items span {
    background-color: $markMainTopic;
    color: black;
} */

.phrase-list-items b

/*, .phrase-list-items.selected b */
    {
    background-color: #e8e8e8;
    color: black;
    font-weight: normal;
}

.phrase-list-items.selected b.phraseHighlight,
.phrase-list-items.selected b.phraseHighlight b {
    background-color: #a6eca6;
    background-color: rgb(249, 165, 27);
}

/* .phrase-list-items mark {
    background-color: rgba(249,165,27);
    color: black;
} */

.candidate-context .phrase-list-items mark {
    background-color: $markMainTopic;
    color: black;
}

.phrase-list-items mark.main-topic {
    background-color: $markMainTopic;
    color: black;
}

.phrase-list-items mark.side-topic {
    background-color: $markSideTopic;
}

.phrase-list-items mark.main-topic {
    background-color: $markMainTopic;
}

.phrase-list-items mark.orange {
    background-color: $markOrange !important;
}

.phrase-list-items mark.green {
    background-color: $markGreen !important;
}

.phrase-list-items mark.yellow {
    background-color: $markYellow !important;
}

.phrase-list-items mark.purple {
    background-color: $markPurple !important;
}

.phrase-list-items mark.pink {
    background-color: $markPink !important;
}

.phrase-list-items mark.indigo {
    background-color: $markIndigo !important;
}

.phrase-list-items mark.lime {
    background-color: $markLime !important;
}

.phrase-list-items mark.brown {
    background-color: $markBrown !important;
}

.phrase-list-items mark.lightblue {
    background-color: $markLightBlue !important;
}


.phrase-list-items mark.main-topic mark.side-topic {
    background-color: $markMainTopic;
    color: black;
}

.phrase-list-items mark.main-topic.phraseHighlight,
.phrase-list-items mark.main-topic.phraseHighlight mark.side-topic {
    background-color: $markMainTopic;
    color: black;
}

.phrase-list-items mark.side-topic.phraseHighlight,
.phrase-list-items mark.side-topic.phraseHighlight mark {
    background-color: #a6eca6;
    background-color: rgb(249, 165, 27);
}

.phrase-list-items mark.candidate-phrase,
.phrase-list-items mark.ngram-phrase {
    background-color: #fff;
    -webkit-text-decoration: #81d4fa double underline;
    text-decoration: #81d4fa double underline;
}

.phrase-list-items>.phrase-context span mark.candidate-phrase,
.phrase-list-items .phrase-context span mark.ngram-phrase {
    background-color: #f9a51b !important;
    text-decoration: none !important;
}

.topics-holder .topics-list {
    /* max-height: 82px; */
    height: 82px;
    scrollbar-width: thin;
    resize: vertical;
    overflow: auto;
}

.topics-holder,
.phrases-holder {
    font-size: 13px;
}

.no-select {
    color: #bbb;
}

.cust-sel {
    text-transform: uppercase;
    font-size: 14px !important;
    margin-bottom: 1px !important;
}

.cust-sel.selected {
    background-color: rgb(217, 230, 249)
}

.MuiListItem-root.cust-sel.Mui-selected,
.MuiListItem-root.cust-sel.Mui-selected:hover {
    background-color: rgb(217, 230, 249)
}

.new-topic {
    display: inline-flex;
    border: 2px dashed $primaryColor;
    padding: 10px 20px;
    margin-right: 10px;
    color: $primaryColor;
    float: left;
    margin-top: 3px;
    cursor: pointer;
}

.topics-list>.MuiButtonBase-root {
    display: inline-flex;
    margin: 0.25rem 0.25rem 0.3rem 0.25rem;
}

.card-header--actions .MuiButton-label {
    text-transform: uppercase;
}

.phrase-context-options {
    padding: 0px;
    margin: 5px 0px;
    list-style: none;
    background: #3a3838;
    border: 0px solid #d2d2d2;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    color: #fff;
    border-radius: 3px;
    display: flex;
    font-size: 11.5px;
    overflow: hidden;
}

.phrase-context-options>li {
    border-right: 1px solid #636161;
    padding: 6px 8px;
    cursor: pointer;
}

.phrase-context-options>li:last-child {
    border-right: 0px solid #636161;
}

.phrase-context-options>li:hover {
    background: #000000;
}

.phrase-context-options>li .MuiSvgIcon-root {
    width: 14px;
    height: 100%;
    display: table-cell;
    font-size: 14px;
    vertical-align: middle;
}

.candidate-btn {
    padding: 8px 16px !important;
    margin-right: 10px !important;
    margin-top: -3px !important;
}

.phrase-title .fa {
    margin-right: 6px;
    font-size: 14px;
    /* color: #4a90ff; */
}

.modal-radio .MuiFormControlLabel-root {
    margin-right: 30px;
}

.emotion-radio-model .MuiFormControlLabel-root {
    margin-right: 0px;
    min-width: 140px;
}

.phrase-title>div.dragging {

    background-color: $redColor;
}

#coverup {
    background: white;
    width: 170px;
    height: 100px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
}

.draged-elem {
    background: #000;
    color: #fff;
    width: auto;
    height: auto;
    display: inline-block;
    text-align: center;
    padding: 8px 10px;
    font-size: 12px;
}

.atd-title {
    display: flex;
    height: 100%;
    align-items: flex-end;
    padding-left: 10px;
}

.atd-title h4 {
    margin-bottom: 6px;
    margin-top: 0;
}

/* .atd-title>div:nth-child(1) {
    max-width: 50%;
    flex-basis: 50%;
}

.atd-title>div:nth-child(2) {
    flex-basis: 470px;
}

.topics-list .MuiChip-root {
    background-color: #d2d2d2;
}

.topics-list .MuiChip-root.MuiChip-outlined {
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 1);
} */
.cust-sel {}

.cust-sel>span {
    flex-grow: 1;
}

.cust-sel>.MuiIconButton-root:hover {
    color: rgba(0, 0, 0, 1) !important;
}

.cust-sel .MuiSvgIcon-root {
    font-size: 1.2rem
}

.sort-btn .MuiSvgIcon-root {
    font-size: 1.2rem
}

.none-btn {
    color: rgba(0, 0, 0, 0.82) !important;
    text-transform: uppercase !important;
    font-size: 15px !important;
}

#phrase-pattern>div>ul>li>.MuiTouchRipple-root {
    display: none !important;
}

#phrase-pattern>.MuiPaper-root {
    width: 400px;
    overflow: hidden;
}

.section1,
.section2,
.section3 {
    white-space: normal;
    font-size: 15px;
    border-bottom: 1px solid #e0e0e2;
    width: 100%;
    padding: 10px;
    text-transform: uppercase;
}

.section1 {
    display: flex;
    min-height: 46px;
}

.section1>div:nth-child(1) {
    flex-grow: 1;
}

.section2 {
    padding-bottom: 20px;
}

.section3 {
    min-height: 100px;
    padding-top: 20px;
    max-height: 200px;
    overflow: auto;
}

#phrase-pattern>div>ul>li.MuiButtonBase-root {
    cursor: default;
    padding: 0px;
    overflow: visible;
}

#phrase-pattern .MuiListItem-button:hover {
    background-color: transparent
}

.s-grid1,
.s-grid3 {
    display: inline-block;
    margin: 0 3px;
    border: 1px solid #3973b6;
    padding: 0px 12px;
    border-radius: 4px;
    margin-bottom: 6px;
    cursor: move;
    background: #fff;
}

.s-grid1 {
    position: relative;
    transition: all 2s;
}

.s-grid2 {
    display: inline-block;
    margin: 0 8px;
    margin-bottom: 6px;
    cursor: pointer;
}

.s-grid2:hover {
    color: #3973b6
}

.s-grid3 {
    cursor: default
}

.s-grid1>span {
    color: $redColor;
    font-size: 14px;
    margin: 0 -5px 0 5px;
    cursor: pointer;
    font-weight: 500;
    display: none;
}

.s-grid1:hover>span {
    display: inline;
}

.fav-pattern {
    display: flex;
    margin-bottom: 8px;
}

.fav-tags {
    flex-grow: 1;
}

.add-to-fav .MuiIconButton-sizeSmall {
    padding: 0px;
}

/* .add-to-fav .MuiSvgIcon-root:hover { color: rgb(255 0 0 / 1); } */
/* .fav-actions .MuiSvgIcon-root { font-size: 1.2rem; width: 24px;
    height: 24px; }
.fav-actions .MuiIconButton-sizeSmall { padding: 0px } */
.fav-actions {
    background: $primaryColor;
    border-radius: 50%;
    height: 26px;
    width: 26px;
    margin-right: 0px;
}

.fav-actions:hover {
    background: $secondaryColor;
}

.fav-actions .MuiSvgIcon-root {
    color: #fff;
    width: 22px;
    height: 20px;
}

.favTitle {
    background: #fff;
    padding: 2px 6px;
    position: absolute;
    font-size: 12px;
    font-weight: 100;
    top: -12px;
    left: 13px;
    font-style: normal;
    z-index: 12;
    color: rgba(0, 0, 0, 0.67);
    text-transform: none;
    font-weight: 500;
}

#phrase-pattern .noData {
    position: absolute;
    font-size: 12px;
    color: #a7a7a7;
    left: 18px;
    top: 12px;
}

.context-topic-tag {
    background-color: $markSideTopic;
    border-radius: 16px;
    padding: 1px 10px;
    text-transform: capitalize;
    font-size: 12px;
    margin-bottom: 2px;
    line-height: 18px;
    color: rgba(0, 0, 0, 1);
    display: table;
}

.context-topic-tag-phrase {
    background-color: $contextTopicTag;
}

.context-topic-tag:hover {
    background-color: $contextTopicTag;
}

.pattern-footer {
    padding: 0px 0px;
    text-align: right;
    display: block;
    width: 100%;
}

.phraseMenu {
    display: flex;
}

.phraseMenu .MuiFormLabel-root {
    font-size: 0.8rem
}

.phraseMenu .MuiInputBase-input {
    padding: 0px 0 10px;
}

.phraseMenu label+.MuiInput-formControl {
    font-size: 13px;
}

.topics-holder .MuiChip-root .MuiChip-avatar {
    position: absolute;
    bottom: -8px;
    margin: 0px;
    width: 18px;
    height: 18px;
    padding: 0px;
}

.topics-holder .fa {
    font-size: 13px;
}

.MuiAvatar-colorDefault {
    background-color: #d9d9d9;
}

.topics-holder .MuiChip-root .MuiChip-avatarColorPrimary {
    background-color: $topicPrimary;
    color: #fff;
}

.topics-holder .MuiChip-root .MuiChip-avatarColorSecondary {
    background-color: $topicSecondary;
    color: #fff;
}

.topics-holder .MuiChip-root.MuiChip-outlined .MuiChip-avatar {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.89);
}

.topics-holder .MuiChip-root.MuiChip-outlinedPrimary .MuiChip-avatarColorPrimary {
    background-color: #fff;
    color: $topicPrimary;
}

.topics-holder .MuiChip-root.MuiChip-outlinedSecondary .MuiChip-avatarColorSecondary {
    background-color: #fff;
    color: $topicSecondary;
}

.export-atd {
    width: 220px;
}

.export-atd .MuiInputLabel-outlined {
    transform: translate(14px, 10px) scale(1);
    font-size: 14px;
}

.export-atd .MuiInputLabel-outlined.MuiInputLabel-shrink {
    /* background-color: #fff; padding: 0 5px;  */
    transform: translate(14px, -6px) scale(0.77);
}

.export-atd .MuiOutlinedInput-adornedEnd {
    padding-right: 0px;
}

.export-atd input {
    padding: 9px 10px;
    font-size: 14px;
}

.export-atd button.MuiButton-contained {
    box-shadow: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

#gram-dropdown-id {
    text-align: left;
}

.phrases-holder .infinite-scroll-component {
    overflow: unset !important;
}

.phrases-holder .phrase-context-list .infinite-scroll-component {
    overflow: auto !important;
    height: auto;
}

.phrase-context-section > div {
    height: 100%;
}

.phrase-palette-division {
    height: 100%;
}

.phraseContextSection {
    /* flex-basis: calc(100% - 340px);
    max-width: calc(100% - 340px);
    flex-grow: 1; 
    margin-right: 20px; */
    height: 100%;
    position: relative;
}

.phrase-title-section {
    height: 100%;
}

.phrase-context-section {
    height: calc(100% - 88px);
    width: 100%;
    overflow: auto;
    white-space: normal;
}

.phrase-context-section.uncla {
    height: 100%;
}

.phrasePhrase {
    width: 200px;
    height: 80px;
    margin: 0 10px 0 0;
    white-space: normal;
    display: inline-table;
}

.phrasePhrase:last-child {
    margin: 0px;
}

.phrase-title {
    display: table-cell;
    vertical-align: middle;
    padding: 5px;
}

.phrase-title>div {
    width: 100%;
}

.phrase-actions {
    height: 18px;
    overflow: hidden;
    display: flex;
    justify-content: space-around;
}

/* .phrase-actions > span { 
    width: 33%;
    display: inline-block;
    height: 100%;
    overflow: hidden;
} */
.phrase-actions>span .MuiIconButton-root {
    color: #888;
}

.phrase-actions>span .MuiIconButton-root:hover {
    color: #000;
}

.phrase-actions .MuiSvgIcon-root {
    font-size: 1.0rem;
}

.phraseContext {
    display: none
}

.paletteSection {
    //height: calc(100% - 8px);
    height: 100%;
    bottom: 0px;
    overflow: hidden auto;
    border: 1px solid #BDBDBD;
    border-radius: 4px;
    // margin-left: 4px;
    transition: height 0.4s;
}

.phrases-holder #phrasesHolder {
    height: calc(100% - 68px);
    overflow: auto;
}

.phrases-holder .renderList {
    height: calc(100% - 66px);
    overflow: hidden;
}

.phrasePhrase.selected .phrase-title {
    color: $borderSelected;
    border: 1px solid #000;
    border: 1px solid $borderSelected;
    color: #000;
    font-weight: 500;
    cursor: move;
}

.phrasePhrase.selected+.phrase-context-section {
    display: block
}

.phrase-name {
    line-height: 14px;
    height: 16px;
    overflow: hidden;
}

.phrase-actions .MuiIconButton-root,
.phrase-actions .MuiIconButton-sizeSmall {
    padding: 0px
}

.phrase-list-items {
    display: flex;
    border-bottom: 1px solid $markSideTopic;
    padding-bottom: 5px;
}

/* .phrase-list-items:last-child {
    border-bottom: 0px solid #bbb;
 } */

.phrase-list-items>.phrase-context-topics {
    flex-basis: 200px;
    max-width: 200px;
}

.phrase-list-items>.phrase-context {
    flex-basis: calc(100% - 200px);
    max-width: calc(100% - 200px);
    flex-grow: 1;
    padding: 0 10px;
    background-color: $whiteColor;
}

.phrase-list-items>.phrase-context.full-width {
    flex-basis: 100%;
    max-width: 100%;
}

.phrase-list-items>.phrase-context.full-width.markClassified {
    background: repeating-linear-gradient(45deg,
            transparent,
            transparent 10px,
            #fff 30px,
            #ddd 10px),
        linear-gradient(to bottom,
            #fff,
            #ddd);
}

.phrase-palette-division .infinite-scroll-component__outerdiv,
.phrase-palette-division .horizontal-scroll {
    display: flex;
    /* justify-content: space-between; */
    white-space: nowrap;
    overflow: auto hidden;
    padding: 0px 0px 3px;
    margin-bottom: 10px;
    height: 88px;
}

.phraseDropped {
    opacity: 1;
    transition: opacity 1000ms ease-in;
    animation: jello-horizontal 1000ms ease-in;
}

.phrase-context-list {
    .infinite-scroll-component__outerdiv {
        display: block;
        white-space: normal;
        height: 100%;
        margin: 0px;
        padding: 0px;
    }
}

.atd-topic-enter {
    opacity: 0;
}

.atd-topic-enter-active {
    opacity: 1;
    transition: opacity 1000ms ease-in;
    animation: jello-horizontal 1000ms ease-in;
}

.atd-topic-exit {
    opacity: 1;
}

.atd-topic-exit-active {
    opacity: 0;
    transition: opacity 600ms ease-in;
    animation: scale-out-tl 600ms ease-in;
}

.atd-topic-exit-active~div {
    animation: slide-left 600ms ease-in;
}

.atd-phrase-exit {
    opacity: 1;
}

.atd-phrase-exit-active {
    opacity: 0;
    transition: opacity 600ms ease-in;
    animation: slide-out-top 600ms ease-in;
}

.atd-phrase-exit-active~div:not(.atd-phrase-exit-active) {
    animation: slide-left-phrase 600ms ease-in;
}

.atd-phrase-delete-exit {
    opacity: 1;
}

.atd-phrase-delete-exit-active {
    opacity: 0;
    transition: opacity 600ms ease-in;
    animation: scale-out-tl 600ms ease-in;
}

.atd-phrase-delete-exit-active~div {
    animation: slide-left-phrase 600ms ease-in;
}

/* .atd-uncla-doc-enter {
    opacity: 0;
}

.atd-uncla-doc-enter-active {
    opacity: 1;
    transition: opacity 1000ms ease-in;
    animation: jello-horizontal 1000ms ease-in;
} */

.atd-uncla-doc-exit {
    opacity: 1;
}

.atd-uncla-doc-exit-active {
    opacity: 0;
    transition: opacity 600ms ease-in;
    animation: slide-out-top-uncla 600ms ease-in;
}

.atd-uncla-doc-exit-active~div {
    /* animation: slide-left-phrase 600ms ease-in; */
    transition: transform 600ms ease-in;
}

/* .atd-uncla-doc-delete-exit {
    opacity: 1;
}
  
.atd-uncla-doc-delete-exit-active {
    opacity: 0;
    transition: opacity 600ms ease-in;
    animation: scale-out-tl 600ms ease-in;
}

.atd-uncla-doc-delete-exit-active ~ div {
    animation: slide-left-phrase 600ms ease-in;
} */

/* ----------------------------------------------
 * Generated by Animista on 2020-10-13 19:42:15
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-out-tl
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-tl {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        opacity: 1;
    }
}

@keyframes scale-out-tl {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        opacity: 1;
    }
}

/**
* ----------------------------------------
* animation slide-left
* ----------------------------------------
*/
@-webkit-keyframes slide-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        -webkit-transform: translateX(-120px);
        transform: translateX(-120px);
    }
}

@keyframes slide-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        -webkit-transform: translateX(-120px);
        transform: translateX(-120px);
    }
}

/**
* ----------------------------------------
* animation slide-left-phrase
* ----------------------------------------
*/
@-webkit-keyframes slide-left-phrase {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        -webkit-transform: translateX(-200px);
        transform: translateX(-200px);
    }
}

@keyframes slide-left-phrase {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        -webkit-transform: translateX(-200px);
        transform: translateX(-200px);
    }
}

/**
 * ----------------------------------------
 * animation fade-out-left
 * ----------------------------------------
 */
@-webkit-keyframes fade-out-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(-50px);
        transform: translateX(-50px);
        opacity: 0;
    }
}

@keyframes fade-out-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(-50px);
        transform: translateX(-50px);
        opacity: 0;
    }
}

/**
 * ----------------------------------------
 * animation jello-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes jello-horizontal {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    30% {
        -webkit-transform: scale3d(1.15, 0.75, 1);
        transform: scale3d(1.15, 0.75, 1);
    }

    40% {
        -webkit-transform: scale3d(0.75, 1.15, 1);
        transform: scale3d(0.75, 1.15, 1);
    }

    50% {
        -webkit-transform: scale3d(1.07, 0.85, 1);
        transform: scale3d(1.07, 0.85, 1);
    }

    65% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
    }

    75% {
        -webkit-transform: scale3d(1.02, 0.95, 1);
        transform: scale3d(1.02, 0.95, 1);
    }

    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes jello-horizontal {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    30% {
        -webkit-transform: scale3d(1.15, 0.75, 1);
        transform: scale3d(1.15, 0.75, 1);
    }

    40% {
        -webkit-transform: scale3d(0.75, 1.15, 1);
        transform: scale3d(0.75, 1.15, 1);
    }

    50% {
        -webkit-transform: scale3d(1.07, 0.85, 1);
        transform: scale3d(1.07, 0.85, 1);
    }

    65% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
    }

    75% {
        -webkit-transform: scale3d(1.02, 0.95, 1);
        transform: scale3d(1.02, 0.95, 1);
    }

    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

/**
 * ----------------------------------------
 * animation slide-out-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        opacity: 0;
    }
}

@keyframes slide-out-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        opacity: 0;
    }
}

/**
 * ----------------------------------------
 * animation slide-out-top for unclassifed
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-top-uncla {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(-200px);
        transform: translateY(-200px);
        opacity: 0;
    }
}

@keyframes slide-out-top-uncla {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(-200px);
        transform: translateY(-200px);
        opacity: 0;
    }
}


.phrase-list-items>.phrase-context span {
    background-color: $orangeColor;
    color: black;
}

.phrase-list-items>.phrase-context span.unbtn,
.phrase-list-items>.phrase-context .unbtn span {
    background-color: transparent;
    color: inherit;
}

.phrase-list-items>.phrase-context span.unbtn {
    font-size: 18px;
    color: #3a3a3a;
}

.unbtn .MuiSvgIcon-root {
    font-size: 1.2rem;
}

.phrase-context {
    line-height: 24px;
}

.phrase-context mark {
    cursor: pointer;
}

.createAtdTopics h4 {
    margin: 0;
    margin-bottom: 5px;
    font-style: italic;
    text-align: center;
}

.ngrams-context mark,
.uncla mark {
    background-color: $markSideTopic;
}

mark.selectedHighlight {
    border: 2px solid $borderSelected;
    border-radius: 2px;
}

.atdState {
    margin-left: 10px;
}

.search-topics label,
.search-phrases label {
    font-size: 0.8rem;
}

.search-topics label+.MuiInput-formControl,
.search-phrases label+.MuiInput-formControl {
    margin-top: 10px;
}

.horizontal-scroll .loadmore-btn {
    width: 200px;
    height: 80px;
    margin: 0 0 0 10px;
    white-space: normal;
    display: inline-table;
    transition: all 0.2s;
}

.horizontal-scroll .loadmore-btn:hover {
    background-color: #f1f1f1;
}

.horizontal-scroll .loadmore-btn>div {
    display: table-cell;
    vertical-align: middle;
    padding: 5px;
    border: 1px dashed #bbb;
    border-radius: 5px;
    text-align: center;
    height: 100%;
    width: 100%;
    cursor: pointer;
}

.data-not-available {
    height: calc(100% + 40px);
    margin: -20px;
    background-color: #f9f9f9;
}

.data-not-available p {
    font-size: 16px;
    margin: 0;
    padding: 20px 40px;
}

.emotionTag {
    font-size: 80%;
    font-weight: 800;
    margin: 0px 4px;
}

.project-statistics {
    margin-bottom: 0px;
}

.fixed-position {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.Workbench-drawer {
    display: table;
    width: 24px;
    right: 0;
    left: auto;
    z-index: 1400;
    transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

    div {
        button { padding: 0px !important }
    }
}

.Workbench-drawer>div {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    text-align: right;
}

.Workbench-drawer>div>button {
    color: #3973b6;
}

.Workbench-drawer.active {
    right: 316px;
}

.Workbench-drawer.active>div>button {
    color: #fff;
}

.thresholdValues {
    max-height: 340px;
    overflow: auto;
    padding: 10px 0px;
    position: absolute;
    width: 100%;
    bottom: 0px;
}

.thresholdValues table {
    width: 100%;
}

.thresholdValues table thead {
    font-weight: 500;
}

.align-right {
    text-align: right;
}

.align-left {
    text-align: left;
}

.inputRow div {
    width: 100%;
    text-align: right;
    font-size: 14px;
}

.banner-alert {
    background: $redColor;
    color: #fff;
    position: absolute;
    width: 100%;
    left: 0px;
    top: 15px;
    height: 40px;
    z-index: 1300;
    text-align: center;
    padding: 10px;
}

.banner-alert>span {
    position: absolute;
    right: 40px;
    top: 9px;
    height: 24px;
    width: 24px;
    cursor: pointer;
    border: 1px solid;
    border-radius: 50%;
}

.banner-alert.invisible {
    visibility: hidden;
}

.atd-lock-icon {
    position: absolute;
    bottom: 20px;
    left: 24px;
    /* transition: bottom 0.4s; */
}

.see-more {
    float: right;
    margin-right: 10px;
    color: #4b90ff;

    .MuiButton-label {
        color: #4b90ff;
    }
}

.spanLabel {
    margin-top: 10px;
}

.display-flex {
    display: flex;
}

.display-inlineflex {
    display: inline-flex;
}

.spanLabel {
    margin-top: 2px;
    margin-right: 5px;
}

.text-blue {
    color: #4b91ff;
}

.sort-head {
    align-items: center;
    margin-top: 15px;
    margin-right: 15px;
    border-right: 1px solid #bbb;
    margin-left: 0px;
    padding-right: 5px;
    padding-left: 0px;
}

.sort-head:last-child {
    border-right: 0px;
}

#context-popover {

    .MuiOutlinedInput-root,
    .MuiPaper-rounded {
        border-radius: 0px;
    }

    .MuiPopover-paper {
        background-color: #828282;
        overflow: visible;
    }

    .MuiPaper-elevation8 {
        box-shadow: none;
    }

    .MuiFormControl-marginNormal {
        margin: 0px;
    }

    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        padding: 0px;
    }

    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
        padding: 5px;
    }

    input {
        color: #fff;
    outline: none;
    font-size: 13px;
    font-weight: 300;
    background-color: #828282;
    padding: 0 0 0 5px;
    height: 100%;
    }

    .MuiSvgIcon-root {
        color: #fff;
        font-size: 1.2em;
        padding-left: 3px;

    }

    .MuiInputAdornment-positionStart {
        margin: 0px
    }

    .MuiInput-root {
        height: 22px;
        overflow: hidden;
    }

    .MuiInput-underline:before,
    .MuiInput-underline:after {
        border-bottom: 0px !important;
    }
}

#searchTopicsAutoComplete-popup {

    text-align: center;
    background-color: #333;

    li {
        font-size: 13px;
        display: table;
        margin: 0 auto;
        border-radius: 20px;
        padding: 2px 10px;
        background-color: #e0e0e0;
        margin-bottom: 5px;
    }
}
#searchInfo { font-size: 11px; }
table#searchInfo thead th,
table#searchInfo tbody td { 
    padding: 0px !important; 
    font-weight: 500; 
    line-height: 12px;
}

.unClassifiedDocs {
    .phrase-context {
        display: flex;
    }
}
