.drillDownHeader {
  padding: 20px 0px;
  margin: 0px;
  flex-grow: 1;
}

.drillDownScrollInfo {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 11;
  left: 0;
  background: #fff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  padding: 20px 28px;
  opacity: 0;
  transition: all 0.5s;
}

.drillDownScrollInfo h4 {
  font-weight: 500;
  font-size: 18px;
  text-transform: uppercase;
  margin: 0 0 10px 0;
  line-height: 20px;
}

.drillDownScrollInfo h3 {
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  line-height: 18px;
  float: left;
  margin-right: 40px;
}

.drillDownScrollInfo h3 span {
  font-weight: 500;
}

.drillDownScrollInfo h3::before {
  content: "■";
  margin-right: 6px;
}

.onScroll .drillDownScrollInfo {
  top: 68px;
  opacity: 1;
}

body.sidebar-off-canvas .drillDownScrollInfo {
  margin-left: 282px;
}

.drillDownProgress {
  height: 100%;
  background-color: #ef8c7b;
  position: absolute;
  background-color: #2196f3;
  bottom: 0;
  width: 10%;
  background-color: rgba(255, 255, 255, 0.078);
  transition: width 0.2s ease-in-out;
}
#drillDownHeader ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
#drillDownHeader ul li {
  display: inline-block;
}
#drillDownHeader .drillDownCheckAll {
  width: auto;
  height: 48px;
  padding-left: 0px;
  margin-top: 0px;
}
#drillDownHeader .drillDownCheckAll .form-group {
  padding-left: 2px;
}
#drillDownHeader .drill-down-header {
  color: #567890;
  color: #2d2d2d;
  height: 48px;
  padding: 0px 0 0 5px;
  margin-top: 0px;
}
#drillDownHeader .drillSearch {
  width: 100%;
  padding: 0 15px;
  display: none;
}
.searchFilterHolder {
  position: relative;
}
.noCount input {
  pointer-events: none;
  border: 1px solid #ccc;
  width: 100%;
  height: 46px;
  padding: 5px 10px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 0px;
  color: hsl(0, 0%, 98%);
  font-size: 16px;
}
.drilldownFilterHolder input {
  height: 32px;
}

#drilldownContent ul {
  list-style: none;
}
#drilldownContent .mainDrillBox {
  padding: 10px 5px 10px 10px;
  margin: 0px 0px 10px 0;
  border-radius: 4px;
  border-left: 0px solid;
  min-height: 88px;
}

.theme01 #drilldownContent .mainDrillBox {
  color: rgba(0, 0, 0, 0.72);
  background: #fff;
  border-color: #ccc;
  border-bottom: 0px solid #6b6b6b;
}

#drilldownContent .mainDrillBox > ul {
  padding: 0px;
  margin: 0px;
  display: flex;
}

#drilldownContent .mainDrillBox .drillDownContentCheckbox {
  width: 52px;
  height: 48px;
}

#drilldownContent .mainDrillBox .drillDownContentDiv {
  flex-grow: 1;
}
#drilldownContent .mainDrillBox .drillDownContentDiv .headingAauthor {
  margin: 0px;
  line-height: 20px;
  color: #424242;
}
.addCollections {
  position: fixed;
  z-index: 99;
  bottom: 80px;
  right: 30px;
}

#drilldownContent .mainDrillBox .drillDownContentDiv p {
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
  line-height: 18px;
  margin-bottom: 4px;
  margin-top: 0px;
  color: rgba(0, 0, 0, 0.72);
  padding-left: 0px;
  border-left: 0px solid;
  border-color: #9e9e9e;
}
#drilldownContent .mainDrillBox.positive .drillDownContentDiv p {
  border-color: #4eaa95;
  color: #288872;
}
#drilldownContent .mainDrillBox.negative .drillDownContentDiv p {
  border-color: #f07f7f;
  color: #da5959;
}
#drilldownContent .mainDrillBox.positive .drillDownContentDiv p:hover {
  color: #4eaa95;
}
#drilldownContent .mainDrillBox.negative .drillDownContentDiv p:hover {
  color: #f07f7f;
}
#drilldownContent .mainDrillBox .drillDownContentDiv a {
  font-size: 13px;
  cursor: pointer;
  color: #3276b1;
  font-style: italic;
}
#drilldownContent .mainDrillBox .drillDownContentDiv a:hover {
  color: #dfeaf3;
}
.infinite-scroll-component .mainDrillBox {
  color: #097fee;
  padding-left: 10px;
  font-weight: 500;
}
.drill-down-header h4 {
  margin: 0px;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 0px;
}
.headingAauthor .author {
  font-size: 16px;
  color: #567890;
  color: #424242;
  font-weight: 500;
}
.headingAauthor .postDateDrilldown {
  font-size: 12px;
  padding: 0px 6px;
  font-weight: normal;
}
.contDiv {
  position: relative;
  margin-right: 10px;
  display: flex;
}

.mediaHolder {
  width: 68px;
  height: 68px;
  overflow: hidden;
  text-align: center;
  margin-right: 12px;
  border-radius: 50%;
  background: #e6eef2;
  background: #ffffff;
  flex: 0 0 68px;
  border: 1px solid #e6eef2;
}

.mediaHolder img {
  padding: 12px 0px;
  width: 40px;
}

.mediaHolder img.my_data {
  padding: 8px 0px;
  width: 42px;
}
.mediaHolder img.api_data {
  padding: 8px 0px;
  width: 50px;
}
.mediaHolder img.email {
  padding: 12px 0px;
  width: 44px;
}
.mediaHolder img.broker_notes {
  padding: 11px 0px;
  width: 44px;
}
.mediaHolder img.typeform {
  padding: 12px 0px;
  width: 40px;
}
.mediaHolder img.bazaarvoice {
  padding: 14px 0px;
  width: 38px;
}
.mediaHolder img.wufoo {
  padding: 13px 0px;
  width: 38px;
}
.mediaHolder img.pinterest, .mediaHolder img.facebook, .mediaHolder img.twitter {
  padding: 14px 0px;
    width: 40px;
}

.contentHolder {
  flex-grow: 1;
}
.contentHolder > span {
  font-size: 11px;
  cursor: pointer;
  color: #3276b1;
  font-weight: 500;
}
.contentHolder > span:hover {
  color: #214e75;
}
.drilldownContent {
  position: relative;
  margin-top: 0px;
  height: 638px;
  overflow: auto;
}

.drilldown-content {
  word-break: break-word;
  font-size: 14px;
  padding: 0 10px;
  max-height: 240px;
  overflow: auto;
  margin: 10px 0px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  position: relative;
}
.drilldown-content-json {
  max-height: 450px;
  overflow: auto;
  padding: 10px;
  color: #333;
  background-color: #f5f5f5;
  margin-top: 10px;
  margin-bottom: 15px;
  overflow: auto;
}

.drillDownContentOptions .MuiIconButton-root {
  color: #949494;
}
.MuiIconButton-root.deleteFab svg{
  font-size: 1.2rem;
}
.MuiIconButton-root.deleteFab:hover {
  color: #ff6c59;
}
.MuiIconButton-root.replyFab:hover {
  color: #06a706;
}
.MuiIconButton-root.addToSpamFab:hover {
  color: #ffa500;
}
.drilldownFilterHolder {
  position: absolute;
  z-index: 99;
  width: 100%;
}
.select__menu {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}
.select__menu-list {
  display: flex;
}
.select__group {
  flex-grow: 1;
}
.select__group {
  border-right: 1px solid #e6e6e6;
}
.select__group:last-child {
  border-right: 0px;
}
.select__option {
  padding: 5px 12px !important;
}
.select__control {
}
.select__value-container > div {
  border-right: 1px solid #d7e2e8;
}
.select__value-container > div:last-child {
  border-right: 0px solid #d7e2e8;
}

.drilldown-content-json pre {
  width: 100%;
  box-sizing: border-box;
  word-break: break-all;
  margin: 0px;
  white-space: pre-wrap;
}
.drilldownFooter button {
  width: 100%;
  padding: 10px 16px;
}

.drilldownContent .frame_tag.label {
  display: inline-block;
  line-height: 1;
  vertical-align: initial;
  margin: 0 5px;
  background-color: #e8e8e8;
  background-image: none;
  padding: 8px;
  color: rgba(0, 0, 0, 0.6);
  text-transform: none;
  font-weight: 700;
  border: 0 solid transparent;
  border-radius: 25px;
  margin-bottom: 5px;
}

.drilldownContent .frame_tag.label,
.drilldownContent .label {
  position: relative;
  padding-left: 14px;
  padding-right: 14px;
  -webkit-transition: none;
  transition: none;
  position: relative;
  font-size: 10px;
}

.drilldownContent .frame_tag.label,
.drilldownContent .label {
  font-size: 10px;
}

.drilldownContent .frame_tag .fa {
  color: rgba(0, 0, 0, 0.6);
  z-index: 1111111;
  font-size: 13px;
  position: absolute;
  left: 11px;
  top: 6px;
}

.content-sentiment-neutral {
  border-left: 4px solid #30c6e8;
}

.content-sentiment-negative {
  border-left: 4px solid #f38e77;
}

.content-sentiment-positive {
  border-left: 4px solid #9ddaa2;
}
#frame-tag > #unclassified {
  display: none !important;
}

#frame-tag > #neutral {
  background: #a9ccc7 !important;
}

#frame-tag > #negative {
  background: #de896f !important;
}

#frame-tag > #positive {
  background: #9ddaa2 !important;
}

#frame-tag > #low {
  background: #deb597 !important;
}

#frame-tag > #medium {
  background: #e8e68c !important;
}

#frame-tag > #high {
  background: #9cde94 !important;
}

#frame-tag > #anger {
  background: #e69481 !important;
}

#frame-tag > #frustration {
  background: #e69481 !important;
}

#frame-tag > #disappointment {
  background: #e69481 !important;
}

#frame-tag > #confusion {
  background: #e69481 !important;
}

#frame-tag > #crave {
  background: #a5de9a !important;
}

#frame-tag > #excitement {
  background: #a5de9a !important;
}

#frame-tag > #gratitude {
  background: #a5de9a !important;
}

#frame-tag > #happiness {
  background: #a5de9a !important;
}

#frame-tag > .persona {
  background: #de9fce !important;
}

#frame-tag > .performance {
  background: #aef1ee !important;
}

#frame-tag > .purchasePath {
  background: #ccb1dc !important;
}

#frame-tag > .topic {
  background: rgb(232, 230, 140) !important;
}
#frame-tag {
  display: none;
  margin-bottom: 15px;
  padding: 0 10px;
}
.contentHolder .drilldown-content + #frame-tag {
  display: block;
}

.deleteContentList p {
  color: rgba(0, 0, 0, 0.78);
}

span.highlightElem {
  color: #3f8da2;
  font-weight: 400;
}

.textDetail-tree {
  max-height: 180px;
  overflow: auto;
}
.model-contextMenuDiv + div {
  z-index: 9999;
}

.drilldownContentLoader {
  padding: 24px;
  text-align: center;
  background-color: rgba(202, 202, 202, 0.56);
  color: #000;
}

.brandContent {
  position: relative;
}

.headerActive .drilldownContent {
  margin-top: 115px;
}

.drilldownContent .ReactVirtualized__List {
  width: 100% !important;
}
.drilldownContent .ReactVirtualized__Grid__innerScrollContainer {
  max-width: 100% !important;
}

.drillDownScrollInfo h4 small, #drillDownContent h1 small {
  font-size: 12px;
    text-transform: initial;
    font-weight: normal;
}

