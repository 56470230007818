.Switch {
  display: flex;
  align-items: center;
  font-size: 10px;

}
.Switch h4 {
  display: inline;
  font-size: 14px;
}
.Switch .toggleSwitch {
  display: flex;
}

.Switch .positive {
  padding-right: 6px;
}
.Switch .positive_active {
  padding-right: 6px;
}
.Switch .negative {
  padding-left: 6px;
}
.Switch .negative_active {
  padding-left: 6px;
}

.Switch .switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 16px;
  background-color: #949da7;
  border-radius: 50px;
  transition: all 0.3s;
}
.Switch .switch::after {
  content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #18cb00;
    top: 1px;
    left: 1px;
    transition: all 0.3s;
}

.Switch .checkbox:checked + .switch::after {
  left : 19px;
  background-color: #e53935
}
.Switch .checkbox:checked + .switch {

}
.Switch .checkbox {
  display : none;
}

.sentimentToggle {
  text-align: left;
}
