.brandComparisonView .rightSide>div {
    border-left: none;
}

.availableBrands {
    padding: 20px;
    max-height: calc(100vh - 58px);
    overflow: auto;
}

.brand-model {
    margin-bottom: 40px;
}

.brand-model h2 {
    font-size: 16px;
    font-weight: 500;
    margin: 0px 0px 10px 0px;
    text-transform: capitalize;
}

.brand-model .MuiFormGroup-row {
    margin: -8px;
}

.brand-model .MuiPaper-rounded {
    margin: 8px;
    overflow: hidden;
}

.brand-grid {
    width: 120px;
    overflow: hidden;
}

.brand-grid.true {
    cursor: not-allowed;
    pointer-events: none;
}

.brand-grid.true .MuiFormControlLabel-root.Mui-disabled {
    cursor: not-allowed;
}

.brand-grid img {
    width: 100%;
    max-width: 100%;
    height: 82px;
}

.brand-grid label,
.brand-grid .MuiFormControlLabel-root {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    padding: 0 0 5px 5px;
}

.brand-grid .MuiFormControlLabel-root .MuiTypography-root {
    font-size: 14px;
}

#available-brands .MuiTypography-h6 {
    font-size: 16px;
}

#available-brands header button {
    padding: 6px;
}

#available-brands header button svg {
    font-size: 22px;
}

#available-brands header button.r-btn {
    padding: 6px 10px;
    margin: 0 4px;
    font-size: 12px;
    letter-spacing: 1px;
}

#available-brands header button.r-btn.cmp-btn {
    background-color: #a55f8a;

}

#available-brands header button.r-btn.cmp-btn:hover {
    background-color: #d08fb7;
}

.brands-comp-div-header {
    display: flex;
    margin: 30px -8px 0px -8px;
}

.brands-comp-div-header .selectedList {
    width: 12%;
    margin: 0 8px;
    background: #e0e0e0;
    border-radius: 4px;
    overflow: hidden;
}

.selectedList img {
    max-width: 100%;
}

.selectedList .MuiFormControlLabel-root {
    width: 100%;
    padding: 0px;
    margin: 0px;
}

.no-brands-selected {
    margin: 0 8px;
}

.brandCompareContent {
    margin-top: 40px;
}

.brandComparisonView .chartTables {
    border-collapse: collapse;
}

.brandComparisonView .chartTables td,
.brandComparisonView .chartTables th {
    padding: 0px 4px;
}

.highcharts-tooltip-box {
    fill: #efefef;
    fill: #f1f1f1;
    padding: 0px;
    /* stroke: #bbbbbb;
    stroke-width: 1px;
    stroke-linejoin: round; */
}

@media (min-width: 600px) {
    #available-brands .MuiToolbar-regular {
        min-height: 58px;
    }
}