$backgroundColor : #ffffff;
$textColor : #212121;
$primaryColor : #1890FF;
$secondaryColor : #4b90ff;
$cardColor : #f1f1f1;
$borderColor : #BDBDBD;
$borderSelected: #4a90ff;
$cardHeaderBorder: rgba(122, 123, 151, 0.3);

$markMainTopic : #feff00;
$markSideTopic : #dcdcdc;
$candidateHighlight : #81d4fa;
$contextTopicTag: #eeef34;

$topicPrimary: #71a21a;
$topicSecondary: #e8795b;

$positiveSentiment: #77b30f;
$negativeSentiment: #e2613e;

$whiteColor: #ffffff;
$redColor : #f83245;
$blueColor : blue;
$greenColor : green;
$orangeColor: rgb(249,165,27);

$markOrange: #ffcc80;
$markGreen: #ccff90;
$markYellow: #fff176;
$markPurple: #d1c4e9;
$markPink: #f8bbd0;
$markIndigo: #c5cae9;
$markLime: #d4e157;
$markBrown: #d7ccc8;
$markLightBlue: #81d4fa;