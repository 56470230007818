.preloaderHolder {
  position: fixed;
  width: 100%;
  height: 100vh;
  text-align: center;
  display: table;
  top: 0;
  left: 0;
  /* background: rgba(14, 14, 14, 0.68); */
  background: rgba(255, 255, 255, 0.86);
  z-index: 999999999;
}

.preloaderDiv {
  display: table-cell;
  vertical-align: middle;
}

.preloader-icon {
  margin: 0 auto;
}

.chartPreloaderHolder {
  width: 100%;
  height: 100%;
  background: rgba(240, 243, 245, 0.4);
  text-align: center;
  display: table;
  z-index: 999;
  position: absolute;
  left: 0px;
  top: 0px;
}

.chartPreloaderDiv {
  display: table-cell;
  vertical-align: middle;
}

.gridPreloaderHolder {
  width: 100%;
  height: 100%;
  display: table;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1099;
  background: rgba(255, 255, 255, 0.82);
}

.gridPreloaderDiv {
  display: table-cell;
  /*vertical-align: middle;*/
  text-align: center;
  color: #000000;
  padding-top: 18%;
}

.gridPreloaderDiv .fa {
  color: #000000;
  padding: 0px;
}

.listViewPreloaderHolder {
  width: 100%;
  height: 100%;
  background: rgba(240, 243, 245, 0.56);
  text-align: center;
  display: table;
  z-index: 999;
  position: absolute;
  left: 0px;
  top: 0px;
}

.listViewPreloaderDiv {
  display: table-cell;
  vertical-align: middle;
}

.listViewPreloaderDiv .preloader-icon {
  background: #fff;
  border-radius: 50%;
  border: 2px solid #fff;
}

.preloaderContent {
  padding: 36px 48px;
  display: table;
  margin: auto;
  /* border-radius: 4px;
  background: #fff;
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2); */
}

.loaderText {
  color: #2d2d2d;
  margin-left: 14px;
  font-size: 12px;
  font-weight: 500;
  margin-top: 2px;
  letter-spacing: 1px;
}

/**
 * ==============================================
 * Bounce Animation
 * ==============================================
 */

.bounceball {
  position: relative;
  display: inline-block;
  height: 37px;
  width: 15px;
  display: none;
}

.bounceball:before {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #2d2d2d;
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: bounce 500ms alternate infinite ease;
  animation: bounce 500ms alternate infinite ease;
}

@-webkit-keyframes bounce {
  0% {
    top: 30px;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    -webkit-transform: scaleX(2);
    transform: scaleX(2);
  }

  35% {
    height: 15px;
    border-radius: 50%;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  100% {
    top: 0;
  }
}

@keyframes bounce {
  0% {
    top: 30px;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    -webkit-transform: scaleX(2);
    transform: scaleX(2);
  }

  35% {
    height: 15px;
    border-radius: 50%;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  100% {
    top: 0;
  }
}

/**
 * ==============================================
 * Squares Spin
 * ==============================================
 */

.four-square {
  position: relative;
  width: 16px;
  height: 16px;
  margin: auto;
  /* top: 0;
  bottom: 0;
  left: 0;
  right: 0; */
}

.square {
  width: 8px;
  height: 8px;
  position: absolute;
  border-radius: 50%;
}

.square-1 {
  background-color: #318bd6;
  top: 0;
  left: 0;
  z-index: 1;
  animation: square-1_move 1.8s cubic-bezier(0.6, 0.01, 0.4, 1) infinite;
}

.square-2 {
  background-color: #f7a436;
  top: 0;
  right: 0;
  animation: square-2_move 1.8s cubic-bezier(0.6, 0.01, 0.4, 1) infinite;
}

.square-3 {
  background-color: #65bc58;
  bottom: 0;
  right: 0;
  z-index: 1;
  animation: square-3_move 1.8s cubic-bezier(0.6, 0.01, 0.4, 1) infinite;
}

.square-4 {
  background-color: #e65e51;
  bottom: 0;
  left: 0;
  animation: square-4_move 1.8s cubic-bezier(0.6, 0.01, 0.4, 1) infinite;
}

@keyframes square-1_move {

  0%,
  100% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(0, 8px);
  }

  50% {
    transform: translate(8px, 8px);
  }

  75% {
    transform: translate(8px, 0);
  }
}

@keyframes square-2_move {

  0%,
  100% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(-8px, 0);
  }

  50% {
    transform: translate(-8px, 8px);
  }

  75% {
    transform: translate(0, 8px);
  }
}

@keyframes square-3_move {

  0%,
  100% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(0, -8px);
  }

  50% {
    transform: translate(-8px, -8px);
  }

  75% {
    transform: translate(-8px, 0);
  }
}

@keyframes square-4_move {

  0%,
  100% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(8px, 0);
  }

  50% {
    transform: translate(8px, -8px);
  }

  75% {
    transform: translate(0, -8px);
  }
}

/**
 * ==============================================
 * Lines Spin
 * ==============================================
 */

.linesAnimate {
  position: relative;
  width: 50px;
  height: 10px;
  background: #3498db;
  border-radius: 5px;
  animation: load 1.8s ease-in-out infinite;
}

.linesAnimate::before {
  position: absolute;
  display: block;
  content: "";
  animation: load 1.8s ease-in-out infinite;
  height: 10px;
  border-radius: 5px;
  top: -20px;
  left: 10px;
  width: 40px;
  background: #ef4836;
}

.linesAnimate::after {
  position: absolute;
  display: block;
  content: "";
  animation: load 1.8s ease-in-out infinite;
  height: 10px;
  border-radius: 5px;
  bottom: -20px;
  width: 35px;
  background: #f5ab35;
}

@keyframes load {
  0% {
    transform: translateX(40px);
  }

  50% {
    transform: translateX(-30px);
  }

  100% {
    transform: translateX(40px);
  }
}

/**
 * ==============================================
 * Dot Spin
 * ==============================================
 */

.snippet {
  position: relative;
  /*background: #fff;*/
  padding: 0.2rem 5%;
  margin: 0.2rem 0;
  /*box-shadow: 0 0.4rem 0.8rem -0.1rem rgba(0, 32, 128, 0.1), 0 0 0 1px #f0f2f7;*/
  border-radius: 0.25rem;
}

.stage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  margin: 0 -5%;
  overflow: hidden;
}

.dot-spin {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: transparent;
  color: transparent;
  box-shadow: 0 -18px 0 0 #1a70b8, 12.72984px -12.72984px 0 0 #65bc58,
    18px 0 0 0 #f7a436, 12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0),
    0 18px 0 0 rgba(152, 128, 255, 0),
    -12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0),
    -18px 0 0 0 rgba(152, 128, 255, 0),
    -12.72984px -12.72984px 0 0 rgba(152, 128, 255, 0);
  animation: dotSpin 1.5s infinite linear;
}

@keyframes dotSpin {

  0%,
  100% {
    box-shadow: 0 -18px 0 0 #1a70b8, 12.72984px -12.72984px 0 0 #65bc58,
      18px 0 0 0 #f7a436, 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }

  12.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 0 #1a70b8, 18px 0 0 0 #65bc58,
      12.72984px 12.72984px 0 0 #f7a436, 0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }

  25% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 0 #1a70b8,
      12.72984px 12.72984px 0 0 #65bc58, 0 18px 0 0 #f7a436,
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }

  37.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 0 #1a70b8,
      0 18px 0 0 #65bc58, -12.72984px 12.72984px 0 0 #f7a436,
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }

  50% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 0 #1a70b8,
      -12.72984px 12.72984px 0 0 #65bc58, -18px 0 0 0 #f7a436,
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }

  62.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 0 #1a70b8,
      -18px 0 0 0 #65bc58, -12.72984px -12.72984px 0 0 #f7a436;
  }

  75% {
    box-shadow: 0 -18px 0 0 #1a70b8,
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 0 #65bc58,
      -12.72984px -12.72984px 0 0 #f7a436;
  }

  87.5% {
    box-shadow: 0 -18px 0 0 #1a70b8, 12.72984px -12.72984px 0 0 #65bc58,
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 0 #f7a436;
  }
}