.listViewComponent .rowGrid .grid {
  padding: 0 10px 0 0;
}

.listViewComponent .rowGrid .grid:last-child {
  padding-right: 0px;
}

.listViewComponent .subRowGrid .subGrid {
  padding: 0 10px 0px 0px;
}

.listViewComponent .subRowGrid .subGrid:last-child {
  padding: 0px;
}

.searchContainer {
  margin: 0px;
}

.listViewSearch svg {
  font-size: 1.26rem;
}

.icon-container {
  width: 40px;
  height: 32px;
  text-align: center;
  display: table;
  color: rgba(0, 0, 0, 0.58);
  cursor: pointer;
}
.icon-container:hover {
  color: rgba(0, 0, 0, 0.89);
}
.icon-container svg {
  /*line-height: 32px;*/
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  text-align: center;
  margin: 0 auto;
}

.searchInput {
  /*height: 32px;
    border: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1875em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  -webkit-tap-highlight-color: transparent;
}

/* .searchInput:hover {
    border-bottom: 2px solid rgba(0, 0, 0, 0.87);
}

.searchInput:focus {
    outline: 0;
    border-bottom: 2px solid rgba(33, 150, 243,0.98);
} */

.hoverOptions {
  position: absolute;
  right: 0px;
  top: 0px;
  margin: 0;
  padding: 0;
  list-style: none;
  height: inherit;
  display: none;
}

.hoverOptions > li {
  float: right;
  width: 28px;
  height: 28px;
  text-align: center;
  display: table;
  cursor: pointer;
  border-radius: 4px;
}

.hoverOptions > li svg {
  font-size: 1.125rem;
}

.hoverOptions > li > .fa {
  display: table-cell;
  vertical-align: middle;
}

.deleteIcon {
  color: #e50352;
}
.deleteIcon button:hover {
  background-color: rgba(229, 3, 3, 0.08);
}
.editIcon {
  color: #039be5;
}
.editIcon button:hover {
  background-color: rgba(33, 150, 243, 0.08);
}

.editList:hover {
  background: #039be5;
  color: #fff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.lsp {
  padding: 5px;
  position: relative;
}

.lsp .MuiTablePagination-toolbar {
  height: 42px;
  min-height: 42px;
}

.lsp .MuiTableCell-sizeSmall {
  padding: 6px 10px 6px 10px;
}

.listViewComponent header {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  height: 38px;
    padding: 0px 15px;
    background-color: #f9f9f9;
}

.listViewComponent .h4 {
  margin: 10px 0px 0px 0px;
  padding: 0px;
  position: relative;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.3125rem;
  display: inline-flex;
  cursor: pointer;
}

.listViewComponent .h4:hover {
  color: rgba(0, 0, 0, 0.87);
}

.listViewComponent .h4 svg {
  width: 18px;
  height: 18px;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin-left: 4px;
  user-select: none;
  margin-right: 4px;
}

.emptyData {
  padding: 0px;
  margin: 0px;
  color: #949494;
  font-style: italic;
  position: relative;
}

.modalCloseButton {
  top: 8px;
  color: #9e9e9e !important;
  right: 8px;
  position: absolute !important;
}

.modalTitle {
  margin: 0;
  padding: 16px;
}

.modalTitle h6 {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  margin: 0px;
}

.candidateThreshold {
  padding: 40px 5px;
}

.renameList {
  position: relative;
}

.renameList input {
  z-index: 1;
}

.renameList .labelHolder {
  display: table;
  position: absolute;
  top: 21px;
  left: 0;
  background: #2a9df5;
  color: #fff;
  z-index: 0;
}

.content-info {
  margin: 0 0 10px 0px;
}

.content-info span {
  font-weight: bold;
  font-style: italic;
}

.content-list {
  max-height: 240px;
  overflow: auto;
}

.content-list > div {
  margin-bottom: 10px;
  padding-bottom: 2px;
  border-bottom: 1px solid #d8d8d8;
}

.lsp .MuiTableHead-root {
  background-color: #f9f9f9;
}

/* MDPI Screen */
@media only screen and (max-resolution: 300dpi) and (min-width: 1280px) {
}

/* HDPI Screen */
@media only screen and (max-resolution: 300dpi) and (min-width: 1440px) {
}


/* .innerGrid .MuiPaper-root {
  border: 1px solid #BDBDBD;
} */

.rowGrid .grid {
  padding: 0 20px;
}

.rowGrid .grid:first-child {
  padding-left: 0px;
}

.rowGrid .grid:last-child {
  padding-right: 0px;
}

.rowGrid .grid .innerGrid {
  margin: 0px 0px 10px 0px;
}

a.btn {
  padding: 6px 10px;
}
.accordianDiv {
  padding: 0;
}

.accordianDiv .accordianHeading {
  /* background: #f3f3f3; */
}

.accordianDiv div[aria-expanded="false"] {
  color: rgba(0, 0, 0, 0.87);
}

.accordianDiv div.accordianHeading[aria-expanded="true"] {
  /* background: rgb(33, 150, 243);
  background: rgb(79, 77, 77);
  color: #fff; */
  min-height: 48px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  margin-bottom: 18px;
}

.accordianDiv div[aria-expanded="true"] > div:first-child {
  margin: 12px 0px;
}

.accordianDiv div[aria-expanded="true"] span {
  color: #ffffff;
}

.el-resize {
  position: absolute !important;
  top:-30px;
  left: 15px;
  display: none !important;
}

.edit_catalogue_file .MuiTextField-root {
  margin: 8px 0px;
}

@media screen and (max-width:700px) {
  .rowGrid .grid { padding: 0px; }
}