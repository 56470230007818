/* @import url("https://fonts.googleapis.com/css?family=Comfortaa:400,500,600,700&display=swap"); */

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0px !important;
  padding: 0px !important;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  /* font-family: 'Comfortaa', cursive; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px;
  letter-spacing: 0.2px;
}

body.theme01 {
  /* background-color: #e4e5e6 !important;
  background-color: #e6eef2 !important; */
  background-color: #f9f9f9 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

strong, b { font-weight: 600; }

.container {
  margin: 0 auto;
  max-width: 1280px;
  width: 90%;
}
.margin-auto {
  margin: auto;
}
.vertical-align-60 {
  height: 60vh;
  display: table;
}
.vertical-align-84 {
  height: 84vh;
  display: table;
}
.vertical-align-60 > div, .vertical-align-84 > div {
  display: table-cell;
  vertical-align: middle;
}

@media only screen and (min-width: 993px) {
  .container {
    width: 70%;
  }
}

@media only screen and (min-width: 601px) {
  .container {
    width: 85%;
  }
}

.App {
  text-align: center;
  /*font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
  /*font-family: 'Comfortaa', cursive; */
  /*font-family: 'Raleway', sans-serif;
  font-family: 'Open Sans', sans-serif;
*/
}

/* React Data Tables - Start */
.ReactTable .rt-thead.-header {
  box-shadow: none;
  background: #dbe5ec;
  color: #333;
}

.ReactTable .rt-thead .rt-th:focus {
  outline: none;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 rgb(33, 150, 243);
}
.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 rgb(33, 150, 243);
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td,
.ReactTable .rt-th,
.ReactTable .rt-td {
  padding: 10px;
  color: #333;
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
  background: #2196f3;
}

.ReactTable .rt-th,
.ReactTable .rt-td {
  position: relative;
}

.rt-tr-group:hover .rowOptions {
  display: block;
}

/* React Data Tables - End */

/* Cust Button - Start */
.btn.btn-default {
  background-color: #eeeeee;
  padding: 7px 15px;
  color: rgba(0, 0, 0, 0.87);
}

.btn.btn-default:hover {
  background-color: #e0e0e0;
}

.btn.btn-primary {
  background-color: #2173b6;
  color: #fff;
}

.btn.btn-primary:hover {
  background-color: #339aef;
}

.btn.btn-text {
  color: #2173b6;
}

.btn.btn-text:hover {
  color: #339aef;
}

.btn.disabled {
  pointer-events: none;
  background-color: #dfdfdf !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #9f9f9f !important;
  cursor: default;
}
/* Cust Button - End */

/* Customized Toaster Css - Start */

.Toastify__toast-container {
  width: 360px;
}

.Toastify__toast {
  border-radius: 2px;
  overflow: hidden;
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 20px;
  min-height: 80px;
  /* text-transform: uppercase; */
  word-spacing: 3px;
  letter-spacing: 0.5px;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)
}

.Toastify__toast--default {
  color: rgba(0, 0, 0, 0.72);
}

.Toastify__toast--info {
  background: #30b9d8;
}
.Toastify__toast--info .Toastify__toast-body {
  color: #ffffff !important;
}

.Toastify__toast--success {
  background: #56b99c;
  background: #4aa98d;
}
.Toastify__toast--success .Toastify__toast-body {
  color: #e6fff2;
}

.Toastify__toast--warning {
  background: #d49413;
  background: #c58b15;
  background: #c5a214;
}
.Toastify__toast--warning .Toastify__toast-body {
  color: rgba(255, 254, 252, 0.89);
}

.Toastify__toast--error {
  background: #e67676;
}
.Toastify__toast--error .Toastify__toast-body {
  color: #ffffff;
}

.Toastify__progress-bar--default {
  /* background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55); */
  background: linear-gradient(to right, #2b2b2b, #000000);
}

/* Customized Toaster Css - End */

.tableContainer {
  overflow: hidden;
}

.formBtnHolder {
  margin: 30px 0 0 0;
}

.formBtnHolder button {
  margin-right: 20px;
}

h1 {
  margin: 12px 0px 12px;
  color: #455a64;
  font-size: 20px;
  letter-spacing: 0.2px;
  color: #000000;
}

.padding-24 {
  padding: 24px;
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}
.text-right {
  text-align: right !important;
}
.position-relative {
  position: relative !important;
}

#alert-dialog-title h6 {
  margin-bottom: -20px;
}

.theme-list > ul > li:hover,
.theme-list > ul > li.Mui-selected {
  background-color: #e6eef2 !important;
}

.theme-list > ul > li .material-icons {
  margin-right: 10px;
}

.editFooter {
  margin: 5px 0px;
}

.flexDisplay {
  display: flex;
}
.flexDisplay > div {
  flex-grow: 1;
}

.editScreen {
  position: relative;
}

.editScreen h1,
.editScreen h2,
.editScreen h3 {
  margin: 0px 0px 20px 0px;
}

.delete-highlight {
  color: #ee5a5b;
  padding-right: 4px;
  font-style: italic;
  font-weight: 500;
}

/* .content-holder {
  position: relative;
} */

.maskElement {
  background: #ffffff;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 10;
}

.MuiTooltip-tooltip {
  font-size: 0.7rem !important;
}

.MuiButton-textPrimary, .MuiButton-containedPrimary, .MuiButton-outlinedPrimary {
  -webkit-transition: color 0.1s !important;
  transition: color 0.1s !important;
}

#logoutIframe { position: absolute;  }

#decoodaApigee, #mykibanDashboard {
  border: 0px !important;
}

/* MDPI Screen */
@media only screen and (max-resolution: 300dpi) and (min-width: 1280px) {
}
/* HDPI Screen */
@media only screen and (max-resolution: 300dpi) and (min-width: 1440px) {
}

@media only screen and (min-width: 768px) {
  .hide-md {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .hide-sm {
    display: none !important;
  }
}


