.container {
  display: flex;
  flex-direction: column;

}

.xAxis{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 50px;
}

.yAxis{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  margin: 6px 0px;
}

.chartContainer{
  display: flex;
  flex-direction: row;

}

.axisTicks {
  color: #ddd;
  font-weight: bold;
  text-transform: lowercase;
}

.scatterPlot {
  height:280px;
  margin: auto auto;
  display: flex;
  flex: auto;
  font-size: 20px;
  justify-content: center;
  align-items: center;
}

.heading {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 24px;

}

