.loginPage {
  height: 100vh;
  overflow: hidden;
  color: #fff;
  flex-grow: 1;
  background-position: center;
  background-size: cover;
  background-color: #ffffff;
  background-repeat: no-repeat;
}

.topLogoPlaceholder {
  display: flex;
  color: #000;
  text-align: left;
  padding: 20px 24px;
  background: #f4f4f4;
  font-size: 13px;
}

.topLogoPlaceholder > div {
  flex-grow: 1;
}

.regDiv {
  text-align: right;
}

.regDiv .regBtn {
  color: #fff;
  background-color: #739e73;
}

.regDiv .regBtn:hover {
  background-color: #5b835b;
}

.regDiv .signInBtn {
  color: #fff;
  background-color: #3276b1;
}
.regDiv .signInBtn:hover {
  background-color: #275b89;
}

.login-info {
  /*background: rgba(35, 104, 129, 0.6);*/
  padding: 5px 20px 20px 0px;
  margin: 0;
  border-radius: 4px;
  text-align: left;
  /*text-shadow: 0px 1px 4px rgba(0,0,0,0.78);*/
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 1px;
  color: #2d2d2d;
  font-size: 14px;
}

.login-info h2 {
  font-size: 24px;
  line-height: 30px;
  color: #a90329;
  font-weight: 400;
  letter-spacing: 0;
  margin-bottom: 10px;
}

.login-info h4 {
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  color: #000000;
  margin: 0px 0px 7px 0px;
}

.login-info p {
  margin: 2px 0 6px 0px;
}

.login-info a {
  color: #3276b1;
  text-decoration: none;
}
.login-info a:hover {
  color: #214e75;
  color: #000000;
}

.ribbonPlaceholder {
    /* display: table;
    height: calc(100% - 100px); */
    max-width: 600px;
}

.ribbionContent {
  /* display: table-cell;
  vertical-align: middle; */
  text-align: left;
}

/*
.ribbonPlaceholder {
    height: 100vh;
    display: table;
}
.ribbionContent {
    display: table-cell;
    vertical-align: middle;
    height: 100vh;
} */

.ribbonPanel {
  color: #d2e1e6;
  color: #fff;
  font-size: 14px;
  background: rgba(35, 104, 129, 1);
  padding: 10px;
  text-align: left;
  position: absolute;
  width: 100%;
  overflow: hidden;
  display: none;
}

.ribbonPanel h2 {
  margin: 0px;
  font-weight: normal;
  font-size: 22px;
}
.ribbonPanel h4 {
  margin: 0px;
  font-weight: normal;
  font-size: 19px;
}
.ribbonPanel p {
  margin: 0px 0px 5px 0px;
}

.ribbonPanel a {
  color: #d2e1e6;
}

.ribbonPanel a:hover {
  text-decoration: none;
}
.loginContainers{
  display: none;
}

/*.gridCont {
    display: flex;
    flex-grow: 1
}

.loginPage > div {
    vertical-align: middle;
    display: table-cell;
} */
/*
.loginContainers {
    height: 100vh;
    display: table;
    margin: 0 auto
}
*/
.paperDiv {
  /*  vertical-align: middle;
    display: table-cell;*/
  margin-top: 20px;
}

.formPanel {
  width: 360px;
  background-color: #fff;
  color: #000;
  margin: 0 auto;
  border-radius: 5px;
  border: 0px solid #ece8e8;
  padding: 15px 0px;
  /* box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 3px 1px -2px; 
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2); */
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.register-form .formPanel {
  width: 60.5%;
}

.formPanelHeader {
  padding: 0px 20px;
  /* background: #256881; */
  /*border-left: 4px solid #256881;*/
  border-left: 4px solid #1a6fb8;
}

.formPanelHeader h4 {
  margin: 0px;
  font-weight: 600;
  /*color: #256881;*/
  color: #1a6eb8;
  text-align: left;
  font-size: 20px;
  text-transform: uppercase;
}

.formPanelHeader img {
  max-width: 100%;
  max-height: 100%;
}

.formPanelFooter {
  padding: 10px;
  display: table;
  background: transparent;
  width: 100%;
}

.formPanelFooter button {
  background-color: #e2e2e2;
  padding: 10px 15px;
  color: #000;
  margin: 0px 10px;
  height: 48px;
  width: calc(100% - 20px);
}

.formPanelFooter button.registerSubmit {
  background-color: #256881 !important;
  color: #fff;
}

.formPanelFooter button:hover {
  background-color: #3b7f98 !important;
  color: #ffffff;
}

.formBtn button.clearBtn {
  background-color: #000;
  color: #fff;
}
.formBtn button.clearBtn:hover {
  background-color: #2e2e2e;
  color: #fff;
}
/*.formPanelFooter .signin {
    background-color: #385764;
} */

.halfWidthButton {
  display: flex;
}

.halfWidthButton button {
  width: 50%;
}

.fullWidthButton button {
  width: 100%;
}

.loginLogo {
  height: 48px;
  vertical-align: middle;
  display: table-cell;
  max-height: 48px;
}

.formPanelBody {
  background-color: #fff;
  text-align: left;
  padding: 15px 20px;
}

.forgotpswd {
  font-size: 14px;
  color: #8a8888;
  cursor: pointer;
}
.forgotpswd:hover {
  color: rgba(0, 0, 0, 0.78);
}

.login-forms {
  display: flex;
  flex-wrap: wrap;
}

.formPanelBody h4 {
  margin: 0px 0px 15px 0px;
  color: #232323;
}

.formPanelBody p {
  margin: 5px 0px 10px 0px;
  font-size: 13px;
}

.formPanelBody svg {
  color: #9e9e9e;
}

.formPanelBody .focused svg,
.formPanelBody .Mui-focused svg {
  color: #2196f3;
}

.register-forms > div > div > div {
  margin-top: 8px;
  margin-bottom: 0px;
}

.error label {
  color: #f44336 !important;
}

.error fieldset {
  border-color: #f44336 !important;
}

.register-forms p {
  font-size: 11px;
  position: absolute;
  background: #222;
  padding: 5px;
  color: #fff;
  right: 0px;
  bottom: 100%;
  border-radius: 1px;
  line-height: 14px;
  /* box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -7px rgba(0,0,0,0.2);*/
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 8px 0px,
    rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px -2px;
  display: none;
}

.register-forms .focused p {
  display: block;
}

.errorMsg {
  /* display: none; */
  color: #f44336;
  color: #cc1c0f;
  font-size: 11px;
  width: 100%;
}

.register-forms .error + .errorMsg {
  display: block;
}

.register-form .formPanelFooter {
  display: none;
}

.clear-both {
  clear: both;
}

.formBtn {
  width: 100%;
  margin-top: 15px;
}

.formBtn button {
  width: 100%;
  background-color: #256881;
  background-color: #1a6fb8;
  height: 52px;
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 2px 1px -1px;
}
.formBtn button:hover {
  background-color: #3b7f98;
  background-color: #2e86d2;
}
/* .formPanel input, .formPanel fieldset, .formPanel .rc-anchor-light {
    background-color: #fff;
} */

.serverError {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.6);
  background: rgba(14, 14, 14, 0.68);
  z-index: 999;
  text-align: center;
  display: table;
}

.serverError .errorAlert {
  display: table-cell;
  vertical-align: middle;
}

.serverError .errorAlertContent {
  display: table;
  background-color: #fff;
  color: rgba(255, 255, 255, 1);
  margin: 0 auto;
  padding: 50px 54px;
  background: #da6565;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  position: relative;
}
.serverError p {
  margin: 0px;
}
.serverError .closeBtn {
  position: absolute;
  top: 4px;
  right: 2px;
  z-index: 1001;
}

.serverError .closeBtn .MuiSvgIcon-root {
  color: rgba(255, 255, 255, 0.6);
}

.serverError .closeBtn:hover .MuiSvgIcon-root {
  color: rgba(255, 255, 255, 1);
}

/* Master Login - start */
.masterlogin {
  height: 100vh;
  overflow: auto;
  color: #222222;
  flex-grow: 1;
  background-position: center;
  background-size: cover;
  background-color: #becad3;
  background-repeat: no-repeat;
  padding: 50px;
}
.login-main {
  display: flex;
  flex-direction: row;
}
.login-main > div:first-child {
  flex-grow: 0;
}

.login-logo-holder img {
  width: 220px;
}
.help-holder {
  text-align: right;
}

.help-holder span {
  color: #2173B6;
  font-size: 20px;
  text-transform: capitalize;
  cursor: pointer;
}

.form-grid {
  width: 360px;
  color: #000;
}
.form-grid.register-form {
  width: auto;
  max-width: 672px;
}

.form-card {
  border-radius: 10px;
  background: #d1dde2;
  border: 0px solid #ece8e8;
  padding: 30px;
  position: relative;
  /* box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);*/
}

.form-grid-title h4 {
  font-weight: 300;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.form-grid .MuiOutlinedInput-root {
  background: #f9f9f9;
}

.form-card .focused svg,
.form-card .Mui-focused svg {
  color: #2173b6;
}

.form-card .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 1px;
}

.form-grid-footer {
  margin: 15px 0px 0px 0px;
}

.form-footer {
  margin: 20px 0px;
}

/* .redirect-block { font-weight: 300; color: #000; } */

.redirect-block span {
  color: #2173B6;
  font-size: 20px;
  text-transform: capitalize;
  cursor: pointer;
}

.custCap > div > div {
  width: 100% !important;
}
.custCap > div > div iframe { width: 100% !important; }



/* .custCap > div > div iframe .rc-anchor { border-radius: 4px;}
.custCap > div > div iframe .rc-anchor-normal {
  border: 1px solid #a1aaae !important;
  box-shadow: none !important;
  background: transparent !important;
  width: 100% !important;
} */

.legend-icon {
  width: 64px;
  height: 64px;
  background: #d1dde2;
  border-radius: 50%;
  display: table;
  color: #1a6fb8;
  position: absolute;
  top: -30px;
  left: 41%;
}

.legend-icon > div {
  display: table-cell;
  vertical-align: middle;
  margin: auto;
}

.legend-icon svg {
  font-size: 32px;
}

.form-grid.register-form .legend-icon { left: 45%; }

.login-holder {
  margin-top: 22px;
}
.login-holder button {
  padding: 10px 24px;
  margin-right: 15px;
  color: #1c5eab;
  border: 1px solid rgb(26 74 154);
}

.login-holder button.Mui-disabled { 
  cursor: not-allowed;
}

/* HDPI Screen */
@media only screen and (max-resolution: 300dpi) and (min-width: 1440px) and (max-height: 900px) {
  .register-form .formPanel {width: 79%;}
}
/* MDPI Screen */
@media only screen and (max-resolution: 300dpi) and (min-width: 1280px) and (max-height: 800px) {
  .register-form .formPanel {width: 89%;}

  .form-card { padding: 24px; }
  .form-footer { margin: 10px 0px; }
  .form-grid-title h4 { margin-top: 5px; }
  
  .register-forms .MuiGrid-spacing-xs-2 > .MuiGrid-item { padding: 0 4px; }
  
}

@media screen and (max-width: 1024px) {
  .formPanel,
  .register-form .formPanel {
    width: calc(100% - 40px);
    margin: 0 20px;
  }

  

  .masterlogin { padding: 15px; background-position: right;
    background-size: inherit;
    background-color: #c0ceda; }
  .form-grid { margin: auto;     margin-top: 30px; }
  .form-card { padding: 20px; }
  .vertical-align-84, .vertical-align-60 { height: auto; display: block }
  .vertical-align-84 > div, .vertical-align-60 > div {     display: block; }
  .form-grid.register-form { max-width: 644px; }
  .register-forms .MuiGrid-spacing-xs-2 > .MuiGrid-item { padding: 0 4px; }

}

@media screen and (max-width: 768px) {
  .masterlogin { background-image: none !important; }
 
  .ribbonPlaceholder {
    max-width: 100%;
  }

  .login-info h2 {
    font-size: 18px;
    line-height: 20px;
  }

  .formPanel {
    width: calc(100% - 40px);
    margin: 0 20px;
  }
}

@media screen and (max-width: 600px) {
  .masterlogin { height: auto; background-image: none !important;  background-color: transparent;}
  .form-grid, .form-grid.register-form { width: 334px; margin-top: 50px; }
  .login-main > div:first-child {     flex-grow: 1;  }
  .form-grid.register-form .legend-icon { left: 40%; }
}

@media screen and (max-width: 340px) {
  .form-grid, .form-grid.register-form { width: 100%; }
}

@media screen and (width: 812px) and (height: 375px) {
  .masterlogin { height: 100%; }
}
 
@media screen and (min-width: 1366px) and (min-height: 768px) {
}
