.notificationMenu ul {
    max-width: 300px;
    padding: 0px;
    margin: 0px;
}
.notificationMenu ul:focus, .notificationMenu li:focus { outline: none !important }
.notificationMenu li {
    color: rgba(0, 0, 0, 0.87);
    min-height: 24px;
    overflow: hidden;
    font-size: 13px;
    box-sizing: content-box;
    line-height: 1.5em;
    white-space: unset;
    position: relative;
    text-align: left;
    padding: 8px 16px;
    outline: none;
    border-bottom: 1px solid #dcdcdc;
}

.notificationMenu li:last-child {
    border-bottom: 0px;
}

.notificationMenu li:hover {
    background-color: rgba(0, 0, 0, 0.05)
}

.notificationMenu > div:nth-child(2) {
    max-width: 320px;
}

.notificationMenu li strong {
    color: #4b91ff;
    font-weight: normal;
}

.notificationMenu li span {
    color: rgba(0, 0, 0, 0.48);
    padding: 0px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    display: block;
    margin-top: 10px;
    /* font-style: italic; */
}

.notificationMenu li a {
    color: #2196f3
}

.notificationMenu li a:hover {
    text-decoration:  none;
}

.notificationMenu li.clearBtn {
    padding: 0px;
    margin-bottom: -8px;
}
.notificationMenu li.clearBtn button {
    width: 100%;
    color: #fff;
    border-radius: 0;
}
.notificationMenu li.clearBtn span{
    color: #fff;
    font-style: normal;
    font-size: 13px;
    font-weight: 500;
    padding: 8px 0px;
}
.notificationMenu li.clearBtn:hover {
    background: transparent;
    cursor: auto;
    padding: 0px;
    margin-bottom: -8px;
}

.notificationMenu .MuiPopover-paper {
    max-width: 360px;
    max-height: calc(100% - 177px);
}

.atdNotification { cursor: pointer; }