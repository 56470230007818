.noBrandSelected {
    margin: 24px 0px;
    padding: 24px;
    color: #5A4E3F;
    background-color: rgba(247, 204, 78, 0.48);
}
.noBrandSelected h3 {
    margin: 0 0 5px 0px;
}
.noBrandSelected small {
    font-size: 14px;
}
.noBrandSelected small span {
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
}
.noBrandSelected small span:hover {
    text-decoration: none;
}