.ReactTable.SummaryTable {
  font-size: 14px;
}
.ReactTable.SummaryTable .rt-thead .rt-resizable-header-content {
  color: rgba(0, 0, 0, 0.98);
  font-size: 13px;
  font-weight: 500;
}
.chartTitleActions {
  display: flex;
}
.chartTitleActions a {
  width: 36px;
  height: 36px;
}
.chartTitleActions a i {
  display: block;
  font-size: 14px;
  text-align: center;
  padding: 10px 0px;
}

.chartTitle .MuiInputBase-root {
  color: #ffff;
  width: 100%;
  padding: 10px 16px;
  position: absolute;
  top: 0;
  right: 0;
  height: 48px;
  background: rgb(45, 45, 45);
  display: none;
  opacity: 0;
}

.fullscreen-enabled .chartTitle .MuiInputBase-root {
  height: 86px;
}

.seachActive .chartTitle .MuiInputBase-root {
  opacity: 1;
  display: flex;
}

.seachActive .chartTitle .MuiInputBase-root .MuiSvgIcon-root {
  color: #fff;
}

.chartGrid {
  position: relative;
  background-color: #fff;
  transition: transform 0.1s;
  border-radius: 5px;
  overflow: hidden;
  height: 460px;
}

.chartGrid:hover {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2),
    0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  transform: translateY(-2px);
}

.chartGrid .fullscreen {
  height: 100%;
}

.chartGrid .MuiInputBase-input {
  font-size: 13px;
  font-weight: 300;
}

.chartTitle {
  height: 48px;
  line-height: 48px;
  padding: 0px 6px 0px 16px;
  margin-bottom: 0;
  position: relative;
  text-transform: capitalize;
  color: #000000;
  font-weight: 500;
  display: flex;
  background: #f9f9f9;
}

.csvhidden { opacity: 0; visibility: hidden; font-size: 0px; }

.chartTitleActions .fa {
  font-size: 16px;
}

.chartTitleActions .fa-expand:before {
  content: "\F065";
}

.fullscreen-enabled .chartTitleActions .fa-expand:before {
  content: "\F066";
}

.highcharts-tooltip-box {
  fill-opacity: 1;
}
.highcharts-tooltip > div > span {
  background: #f8f8f8;
}

.chartLabel {
  flex-grow: 1;
}

.chartContent {
  height: calc(100% - 48px);
  text-align: center;
  width: 100%;
  overflow: auto;
  padding: 15px;
  display: table;
  position: relative;
}

.chartContent .noData {
  padding: 0px;
  color: #9e9e9e;
  font-style: normal;
  vertical-align: middle;
  height: 100%;
  display: table-cell;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  font-size: 20px;
}

.chartContent .highcharts-container {
  margin: 0 auto;
  width: 100% !important;
  text-align: center !important;
}

.chartContent .MuiTableCell-root { padding: 10px; }

.chartContent .MuiIconButton-root { padding: 4px; }

.chartContent table thead th { padding-top: 9px!important;  }

.chartContent .MuiTablePagination-toolbar { min-height: 42px; }

.chartDataTablesFooter {
  flex-shrink: 0;
}

.mediaTables a {
  cursor: pointer;
  color: #3071a5;
}

.mediaTables a:hover {
  color: #09508a;
  font-weight: bold;
}

.chartGrid .fullscreen-enabled {
  background: #fff;
  overflow: auto;
}

.fullscreen-enabled .chartTitle {
  font-size: 18px;
  padding: 0px 50px 0px 28px;
  height: 88px;
  line-height: 88px;
  background: #5d5b5b;
  color: #fff;
}

.fullscreen-enabled .chartTitleActions {
  top: 0px;
  right: 16px;
  color: #fff;
}

.fullscreen-enabled .chartTitleActions .MuiIconButton-root {
  color: #fff;
  width: 88px;
  height: 88px;
}

.fullscreen-enabled .chartTitleActions .fa {
  font-size: 24px;
}

.fullscreen-enabled .chartContent {
  height: calc(100% - 88px);
}
/*
.fullscreen .chartContent > div {
    height: 100%;
}

.fullscreen.fullscreen-enabled .chartContent > div {
    height: 60vh;
} */

.fullscreen-enabled .chartContent table {
  height: 80vh;
}

.fullscreen-enabled .chartContent .MuiTableCell-head,
.fullscreen-enabled .chartContent .MuiTypography-caption {
  font-size: 0.875rem;
}

.fullscreen-enabled .chartContent .MuiTableCell-body {
  font-size: 1rem;
  padding: 10px 40px 10px 16px;
}

.chartHolder {
  height: 100%;
}

.highcharts-input-group {
  display: none
}