.landing {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #3973b6;
  background-repeat: no-repeat;
  background-size: 101%;
  background-position-y: -2px;
  background-position-x: -2px;
  padding: 50px;
  overflow: hidden;
  font-family: "Open Sans", sans-serif;
}

.landing .userInfo {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  font-size: 12px;
  height: 40px;
  display: flex;
}
.landing .userInfo .user-info {
  display: table;
  height: 100%;
}

.landing .userInfo .user-info span {
  display: table-cell;
  vertical-align: middle;
}

/* .landing .overlay-bg {
    background-color: rgba(49, 38, 5, 0.62);
    width: 100%;
    height: inherit;
} */

.landing h1 {
  margin-top: 0px;
  text-transform: capitalize;
}
.landing h3 {
  margin-top: 0px;
  width: 100px;
  height: 100px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  padding-top: 29px;
  font-size: 28px;
  margin: auto;
  margin-bottom: 20px;
  color: #3973b6;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
}

.apps-container {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: row;
}
/*
.apps-list .app-logo { display: none }

.apps-list {
    padding: 20px;
    height: 220px;
    cursor: pointer;
    margin: 0 10px;
    color: #fff;
    border-radius: 20px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),
     0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}
.apps-list:nth-child(1) {
    background: #2173B6;
}
.apps-list:nth-child(2) {
    background: #E83D39;
}
.apps-list:nth-child(3) {
    background: #F5A634;
}
.apps-list:nth-child(4) {
    background: #68B758;
}
.apps-list:nth-child(5) {
    background: #2173B6;
}

.apps-list:nth-child(1) h3{
    color: #2173B6;
}
.apps-list:nth-child(2) h3{
    color: #E83D39;
}
.apps-list:nth-child(3) h3{
    color: #F5A634;
}
.apps-list:nth-child(4) h3{
    color: #68B758;
}
.apps-list:nth-child(5) h3{
    color: #2173B6;
}
*/
.logo-holder {
  width: 240px;
}

.logo-holder img {
  max-width: 100%;
}

.highlight {
  color: #f5a634;
  text-align: left;
}
.highlight h1,
.highlight h4 {
  color: #f5a634;
}
.highlight h1 {
  font-size: 46px;
  margin-bottom: 0px;
}
.highlight h4 {
  font-size: 23px;
  font-weight: 300;
  margin: 0px;
}

.appDetails {
  color: #fff;
  width: 310px;
  padding: 15px 15px;
}
.appDetails small {
  color: #bfbfbf;
  /* color: #f5a634; */
}
.a-lists {
  margin-top: 20px;
}
.a-lists .listitem {
  text-align: center;
  padding: 18px 40px;
  margin: 0px 0px 20px 0px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.a-lists .listitem:hover {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.a-lists .listitem:nth-child(1) {
  background-color: #2173b6;
  background-color: #2b89d6;
}
.a-lists .listitem:nth-child(2) {
  background-color: #e83d39;
}
.a-lists .listitem:nth-child(3) {
  background-color: #f5a634;
}
.a-lists .listitem:nth-child(4) {
  background-color: #68b758;
}
.a-lists .listitem:nth-child(5) {
  background-color: #c2185b;
}

.a-lists .listitem:nth-child(6) {
  background-color: #68b758;
}
.a-lists .listitem:nth-child(7) {
  background-color: #68b758;
}
.a-lists .listitem:nth-child(8) {
  background-color: #68b758;
}

.a-lists .listitem p {
  margin: 0px;
  font-weight: 300;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.7);
}

.a-lists .listitem p span {
  font-weight: 600;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 1);
}
.a-lists .listitem.app-disabled {
  cursor: not-allowed;
  /* pointer-events: none; */
  color: rgba(0, 0, 0, 0.26);
}

.a-lists .listitem.app-disabled p,
.a-lists .listitem.app-disabled p span {
  color: rgba(0, 0, 0, 0.42);
}
.a-lists .listitem.app-disabled {
  background-color: rgb(121, 121, 121);
  box-shadow: none;
}

.a-lists .listitem.app-disabled:nth-child(1) {
  background-color: #8799a7;
}
.a-lists .listitem.app-disabled:nth-child(2) {
  background-color: #b19a9a;
}
.a-lists .listitem.app-disabled:nth-child(3) {
  background-color: #b5a896;
}
.a-lists .listitem.app-disabled:nth-child(4) {
  background-color: #9fb19b;
}
.a-lists .listitem.app-disabled:nth-child(5) {
  background-color: #b3abae;
}
/*
.userInfo .MuiIconButton-root {
    width: 28px;
    height: 28px;
    padding: 1px;
    overflow: hidden;
    border: 1px solid #fff;
} */
.userInfo .fa {
  color: #fff;
  font-size: 16px;
}
.userInfo .fa.fa-caret-down {
  font-size: 12px;
  padding-left: 8px;
}

#landing-menu .MuiMenu-paper {
  background: #454b51;
}
#landing-menu .MuiMenuItem-root {
  color: #fff;
  font-size: 0.8rem;
}

.okta-user-update h4 {
  margin-top: 5px;
  font-weight: 600;
  font-size: 18px;
}

.okta-user-update .MuiFormLabel-root { font-size: 13.5px; }
.okta-user-update .MuiInputBase-input {
  font-size: 14px;
}

.okta-user-update .MuiInputLabel-outlined {
  transform: translate(14px, 14px) scale(1);
}
.okta-user-update .MuiFormControl-marginNormal {
  margin-top: 12px;
  margin-bottom: 6px;
}

.okta-user-update .MuiOutlinedInput-input {
  padding: 12.5px 14px;
}

.okta-user-update-footer {
  margin-top: 10px;
}

.okta-user-update-footer button { margin: 0px 2px;     font-size: 13px;
  text-transform: capitalize; }

.userInfo .MuiIconButton-root:hover {
  background-color: transparent;
}

.toogle-password {
  text-align: left;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    display: table;
    margin: 10px auto 0px auto;
    color: #F5A634;
}

.custCard .MuiPaper-rounded {
  border-radius: 10px;
}