.no-suggestions {
    color: #2b2a2a;
    padding: 0.2rem 0.5rem;
}

.suggestions {
    list-style: none;
    margin-top: 0;
    overflow-y: auto;
    width: 100%;
    position: absolute;
    background-color: #333;
    bottom: 10px;
    padding: 5px 0px 0px 0px;
    text-align: center;

    li {
        font-size: 12px;
        display: table;
        margin: 0 auto;
        border-radius: 20px;
        padding: 1px 10px;
        background-color: #e0e0e0;
        margin-bottom: 5px;
        text-align: center;

        &:hover {
            background-color: #d0d0d0;
            cursor: pointer;

        }

        &:not(:last-of-type) {
            border-bottom: 1px solid #999;
        }
    }
}

#context-popover {
    .newList {
        display: inline-flex;

        .MuiSvgIcon-root {
            color: #333;
            font-size: 1.2rem;
        }
    }


    .newTopicPopup {
        position: absolute;
        bottom: 10px;
        background: #e0e0e0;
        width: 100%;
        list-style: none;
        font-size: 13px;
        padding: 0px;

        li {

            border-bottom: 1px solid #929292;
            text-align: center;
            height: 28px;

            &.sentimentIcons {
                background-color: #f2f2f2;
                height: 28px;
                display: flex;
                padding-top: 3px;

                .MuiFormControl-root {
                    width: 100%;
                }

                .MuiButtonBase-root {
                    padding: 0px;
                }
                .MuiFormGroup-row {
                    justify-content: space-evenly;
                }
            }

            input {
                background-color: transparent;
                border: 0px;
                width: 100%;
                height: 100%;
                padding: 5px 10px;
                color: #777;
            }
        }

        .MuiButton-root {
            width: 50%;
            border-right: 1px solid;

            &:last-child {
                border-right: 0px solid;
            }
        }

        .MuiButton-text {
            padding: 0px;
            color: #e0e0e0;
        }

        .MuiButton-label {
            background-color: #828282;
            text-transform: capitalize;
            font-size: 12px;
            padding: 3px 0px;
            font-weight: 400;
        }

        .Mui-disabled {
            .iconBtn { color: #bbb; }
        }
    }



    .iconBtn {
        padding: 0px;
        width: 22px;
        height: 22px;
        color: #777;

        &.negative {
            color: #d44f27
        }

        &.neutral {
            color: #edaa4b
        }

        &.positive {
            color: #3ed41a
        }
    }
}